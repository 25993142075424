// src/pages/FormationPages/ResponsabiliteSocietale.js
import React, { useState } from 'react';
import './responsabilitesocietale.css'; // Import the CSS file
import Header from '../../components/Header'; // Import the Header component
import Footer from '../../components/Footer'; // Import the Footer component

// Sample data for the trainings
const trainings = [
    {
        id: 'sustainable-practices',
        title: 'Exigences de la Norme SA 8000:2014',
        details: [
            { 
                detail: "Description", 
                description: "Découvrez comment développer un système de management de la responsabilité sociale conforme à la norme SA 8000:2014."
            },
            { 
                detail: "Objectives", 
                description: (<p> • Analyser et interpréter les exigences de la norme SA 8000:2014 et savoir les appliquer à son entreprise.<br/> • Etre en mesure de faire le lien avec les exigences légales et règlementaires tunisiennes.<br/> • Etre en mesure d’atteindre et de maintenir la conformité de votre système avec les exigences de la norme SA 8000:2014</p>)
            },
            { 
                detail: "Duration", 
                description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: "29-31 / 05 / 2024"
            },
            { 
                detail: "Price", 
                description: "900 DT(HT) /participant"
            }
        ]
    },
    {
        id: 'corporate-social-responsibility',
        title: 'Auditeur Interne SA 8000:2014',
        details: [
            { 
                detail: "Description", 
                description: "Cette formation vous permettra d’acquérir la qualification d’auditeur interne SA 8000."
            },
            { 
                detail: "Objectives", 
                description: (<p> •Acquérir la qualification « auditeur interne SA 8000:2014 ».<br/> • Maitriser les techniques d’audit et de communication en situation d’audit et connaitre les principales exigences légales et règlementaires relatives aux obligations sociales de l’entreprise en Tunisie.<br/> • Etre capable de réaliser des audits internes à haute valeur ajoutée • Savoir rédiger un rapport d’audit basé sur l’amélioration.</p>)
            },
            { 
                detail: "Duration", 
                description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: "25-27/ 09 / 2024"
            },
            { 
                detail: "Price", 
                description: "900 DT(HT) /participant"
            }
        ]
    }
];

const ResponsabiliteSocietale = () => {
    const [selectedTraining, setSelectedTraining] = useState(null);

    // Function to handle detail view
    const handleDetailsView = (training) => {
        setSelectedTraining(training);
    };

    // Function to go back to the catalog
    const handleBackToCatalog = () => {
        setSelectedTraining(null);
    };

    // Render the catalog view
    const renderCatalog = () => (
        <div className="training-catalog">
            <h1 className="catalog-title">Formations - Pésponsabilité Sociétale</h1>
            <div className="training-list">
                {trainings.map((training) => (
                    <div key={training.id} className="card-centered">
                        <h3 className="card-title">{training.title}</h3>
                        <p className="card-description">{training.description}</p>
                        <button onClick={() => handleDetailsView(training)} className="btn">
                            En savoir plus
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );

    // Render the details view
    const renderDetails = () => (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
            Retour au Catalogue
            </button>
            <table className="details-table">
                <thead>
                    <tr>
                        <th colSpan="2" className="details-title">{selectedTraining.title}</th>
                    </tr>
                    <tr>
                        <th>Detail</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedTraining.details.map((detail, index) => (
                        <tr key={index}>
                            <td>{detail.detail}</td>
                            <td>{detail.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="min-vh-100">
            <Header /> {/* Include the Header */}
            <div className="catalog-spacing" /> {/* Add spacing below the header */}
            {selectedTraining ? renderDetails() : renderCatalog()}
            <Footer /> {/* Include the Footer */}
        </div>
    );
};

export default ResponsabiliteSocietale;
