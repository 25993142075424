import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './TrainingDetail.css';

// Example static mock data
const mockTrainingData = [ { 
    name: "lean-introduction", 
    title: "Lean Six Sigma Green Belt", 
    description: "Chef de projet d’amélioration de moyenne complexité, le Lean Six Sigma Green Belt met en œuvre la méthodologie DMAIC en vue d’améliorer un processus de manière conséquente.",
    details: [
        { detail: "Description", description: "Chef de projet d’amélioration de moyenne complexité, le Lean Six Sigma Green Belt met en œuvre la méthodologie DMAIC en vue d’améliorer un processus de manière conséquente." },
        { detail: "Objectives", description: "Connaitre les principes du Lean Six Sigma et la méthodologie DMAIC Maitriser les outils statistiques et analytiques fondamentaux du Six Sigma Maitriser les outils fondamentaux du Lean Management Apprentissage du logiciel d’analyse statistique MINITAB Réaliser un projet de niveau Green Belt en déployant le cycle DMAIC" },
        { detail: "Duration", description: "10 jours (2+2+3+3) Horaire de la formation: de 08h30 à 16h00 (pauses incluses)" },
        { detail: "Dates des sessions 2024 :", description: "Session 1: 15-16/04, Session 2: 09-10/05, Session 3: 19-20/09" },
        { detail: "Price", description: "3 500 DT(HT) / participant" },
        { detail: "Certification", description: "À la fin de cette formation, vous recevrez un certificat de réussite Lean Six Sigma Green Belt, attestant de vos compétences en gestion de projets d’amélioration." }
    ]
},
{ 
    name: "react-training", 
    title: "React Training", 
    description: "Learn React from scratch.", 
    objectives: ["Understand JSX", "Learn state management", "Build React apps"],
    duration: "4 hours",
    dates: "2024-11-20 to 2024-11-22",
    price: "$50"
},
{ 
    name: "angular-training", 
    title: "Angular Training", 
    description: "Learn Angular framework.", 
    objectives: ["Understand components", "Learn directives", "Build Angular apps"],
    duration: "5 hours",
    dates: "2024-11-23 to 2024-11-25",
    price: "$60"
}
];
const TrainingDetail = () => {
    const { name } = useParams(); // Get the training name from the URL
    const [trainingDetails, setTrainingDetails] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Find training details based on the name parameter
        const training = mockTrainingData.find(training => training.name === name);
        if (training) {
            setTrainingDetails(training);
        } else {
            setError("Training not found");
        }
    }, [name]);

    if (error) return <p>{error}</p>;
    if (!trainingDetails) return <p>Loading...</p>;

    return (
        <div style={{ padding: '20px' }}>
            <h1>{trainingDetails.title}</h1>
            <p>{trainingDetails.description}</p>

            <table className="details-table">
                <thead>
                    <tr>
                        <th><i className="fas fa-bullseye"></i> Objectives</th>
                        <th><i className="fas fa-clock"></i> Duration</th>
                        <th><i className="fas fa-calendar-alt"></i> Dates</th>
                        <th><i className="fas fa-dollar-sign"></i> Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <ul>
                                {trainingDetails.objectives.map((objective, index) => (
                                    <li key={index}>{objective}</li>
                                ))}
                            </ul>
                        </td>
                        <td>{trainingDetails.duration}</td>
                        <td>{trainingDetails.dates}</td>
                        <td>{trainingDetails.price}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TrainingDetail;
