import React, { Component } from 'react';
import bg1 from "../Images/lmi.png";
import './HomeSlider.css'; // Ensure your CSS is imported

class HomeSlider extends Component {
    render() {
        return (
            <div className="container-fluid p-0 mb-5" style={{ marginTop: '20px' }}>
                <div className="position-relative d-flex justify-content-center">
                    <div className="slider-container">
                        <img 
                            className="img-fluid" 
                            src={bg1} 
                            alt="Event showcase"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSlider;
