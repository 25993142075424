// src/pages/Catalogue.js

import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaChalkboardTeacher, FaCogs, FaLightbulb, FaProjectDiagram, FaLeaf, FaHeartbeat, FaPeopleCarry, FaBalanceScale } from 'react-icons/fa';  // Importing icons
import './catalogue.css';

const Catalogue = () => {
  const themes = [
    { title: "Leadership et Stratégie", path: "/formation/leadership-strategie", icon: <FaChalkboardTeacher />, color: "#3b7c3a" },
    { title: "Lean Manufacturing", path: "/formation/lean-manufacturing", icon: <FaCogs />, color: "#007bff" },
    { title: "Lean Six Sigma", path: "/formation/lean-6sigma", icon: <FaCogs />, color: "#f39c12" },
    { title: "Méthodes de la Qualité", path: "/formation/methode-qualite", icon: <FaBalanceScale />, color: "#e74c3c" },
    { title: "Innovation et Conception", path: "/formation/innovation-conception", icon: <FaLightbulb />, color: "#9b59b6" },
    { title: "Gestion des Projets", path: "/formation/gestion-projets", icon: <FaProjectDiagram />, color: "#2ecc71" },
    { title: "Environnement", path: "/formation/environnement", icon: <FaLeaf />, color: "#27ae60" },
    { title: "Santé et Sécurité au Travail", path: "/formation/sante-securite", icon: <FaHeartbeat />, color: "#f1c40f" },
    { title: "Responsabilité Sociétale", path: "/formation/responsabilite-societale", icon: <FaPeopleCarry />, color: "#34495e" },
  ];

  return (
    <div>
      <Header page="Catalogue" />

      <div className="container my-5">
        {/* Card Layout for Themes */}
        <div className="row">
          {themes.map((theme, index) => (
            <div className="col-lg-4 col-md-6 mb-4" key={index}>
              <Link to={theme.path} style={{ textDecoration: 'none' }}> {/* Add Link here */}
                <div className="card shadow-sm" style={{ borderLeft: `5px solid ${theme.color}`, borderRadius: '8px', overflow: 'hidden' }}>
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div style={{ fontSize: '30px', color: theme.color }} className="me-3">
                        {theme.icon}
                      </div>
                      <h5 className="card-title theme-title">{theme.title}</h5>
                    </div>
                    <p className="card-text">{theme.description}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Catalogue;
