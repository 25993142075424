// src/pages/FormationPages/GestionDesProjets.js
import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './gestiondesprojets.css'; // Import the CSS file

// Sample data for the trainings
const trainings = [
    {
        id: 'project-management-introduction',
        title: 'Gestion de Projet Agile: Méthode Scrum',
        details: [
            { 
                detail: "Description", 
                description: "Les méthodes Agiles, et en particulier Scrum, sont devenues le nouveau standard de gestion des projets de développement. Elles permettent de mieux maîtriser les délais, les coûts tout en augmentant la qualité des produits."
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                        • Connaître les principes, les valeurs et les avantages de la méthode Scrum <br />
                        • Connaître les rôles de la méthode Scrum et leurs responsabilités associées <br />
                        • Être en mesure de développer le backlog du produit et de planifier les releases du produit <br />
                        • Être en mesure de mettre efficacement en œuvre la planification, la réalisation, la revue et la rétrospective des sprints
                    </p>
                )
            },
            { 
                detail: "Duration", 
                description: "4 jours Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: "Session 1: 23-26 / 04 / 2024 • Session 2: 19-22 / 11 / 2024"
            },
            { 
                detail: "Prix", 
                description: " 1 200 DT(HT) /participant"
            }
        ]
    },
    {
        id: 'agile-methodologies',
        title: 'Fondamentaux de la Gestion de Projet',
        details: [
            { 
                detail: "Description", 
                description: "Développez et approfondissez vos compétences en gestion de projet. Une gestion de projet efficace permet de mieux maîtriser les délais, les coûts tout en augmentant la qualité des produits."
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                       • Assimiler les différentes phases de la conduite d’un projet de la phase d’étude à la clôture du projet<br/>
• Savoir organiser un projet, connaitre la gouvernance du projet les 
acteurs et leurs contributions<br/>
• Assimiler et être en mesure d’utiliser les méthodes et outils de la 
gestion de projet<br/>
• Connaitre et être en mesure de gérer les défis et les risques inhérents 
à la conduite d’un projet<br/>

                    </p>
                )
            },
            { 
                detail: "Duration", 
                description: "3 jours Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: "Session 1: 04-06 / 03 / 2024 , Session 2: 09-11 / 10 / 2024"
            },
            { 
                detail: "Prix", 
                description: " 900 DT(HT) /participant"
            }
        ]
    }
];

const GestionDesProjets = () => {
    const [selectedTraining, setSelectedTraining] = useState(null);

    // Function to handle detail view
    const handleDetailsView = (training) => {
        setSelectedTraining(training);
    };

    // Function to go back to the catalog
    const handleBackToCatalog = () => {
        setSelectedTraining(null);
    };

    // Render the catalog view
    // Render the catalog view
const renderCatalog = () => (
    <div className="training-catalog">
        <h1 className="catalog-title">Formations - Gestion des projets</h1> {/* Add the title */}
        <div className="training-list">
            {trainings.map((training) => (
                <div key={training.id} className="card-centered">
                    <h3 className="card-title">{training.title}</h3>
                    <p className="card-description">{training.description}</p>
                    <button onClick={() => handleDetailsView(training)} className="btn">
                        En savoir plus
                    </button>
                </div>
            ))}
        </div>
    </div>
);


    // Render the details view
    const renderDetails = () => (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
            Retour au Catalogue
            </button>
            <table className="details-table">
                <thead>
                    <tr>
                        <th colSpan="2" className="details-title">{selectedTraining.title} </th>
                    </tr>
                    <tr>
                        <th>Details</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedTraining.details.map((detail, index) => (
                        <tr key={index}>
                            <td>{detail.detail}</td>
                            <td>{detail.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="min-vh-100">
            <Header />  {/* Add Header here */}
            {selectedTraining ? renderDetails() : renderCatalog()}
            <Footer />  {/* Add Footer here */}
        </div>
    );
};

export default GestionDesProjets;
