// src/pages/FormationPages/SanteEtSecuriteAuTravail.js
import React, { useState } from 'react';
import './santeetsecuriteautravil.css'; // Import the CSS file
import Header from '../../components/Header'; // Import the Header component
import Footer from '../../components/Footer'; // Import the Footer component

// Sample data for the trainings
const trainings = [
    {
        id: 'workplace-safety',
    title: 'Exigences de la Norme ISO 45001:2018',
    details: [
        { 
            detail: "Description", 
            description: "Découvrez comment développer un système de management de la santé et de la sécurité au travail efficace et efficient conforme à la norme ISO 45001:2018."
        },
        { 
            detail: "Objectives", 
            description: (<p>•  Analyser et interpréter les exigences de la norme ISO 45001:2018 et savoir les appliquer à son entreprise.<br/> • Connaitre les outils et méthodes nécessaires pour la mise en place d’un SMSST conforme aux exigences de la norme ISO 45001:2018.<br/> • Connaitre les principales exigences légales et règlementaires relatives à la santé et à la sécurité au travail applicables en Tunisie.</p>)
        },
        { 
            detail: "Duration", 
            description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Dates des sessions :", 
            description: "Session 1 : 17-19 / 04 / 2024 , Session 2 : 16-18 / 10 / 2024"
        },
        { 
            detail: "Price", 
            description: "850 DT(HT) /participant"
        }
    ]
    },
    {
        id: 'health-auditor-internal',
    title: 'Auditeur interne ISO 45001:2018',
    details: [
        { 
            detail: "Description", 
            description: "La norme ISO 45001 positionne l’audit interne comme un outil de management incontournable pour la vérification de l’efficacité des systèmes de management de la santé et de la sécurité au travail."
        },
        { 
            detail: "Objectives", 
            description: (<p>•Acquérir la qualification « auditeur interne ISO 45001:2018 ».<br/> • Maitriser les techniques d’audit et de communication en situation d’audit et connaitre les principales exigences légales et règlementaires relatives à la santé et à la sécurité au travail en Tunisie.<br/> • Etre capable de réaliser des audits internes à haute valeur ajoutée.<br/> • Savoir rédiger un rapport d’audit basé sur l’amélioration.</p>)
        },
        { 
            detail: "Duration", 
            description: "2 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Dates des sessions :", 
            description: "27-28 / 05 / 2024"
        },
        { 
            detail: "Price", 
            description: "750 DT(HT) /participant"
        }
    ]
    },
    {
        id: 'dangerous-products-handling',
        title: 'Stockage et Manipulation des Produits Dangereux',
        details: [
            { 
                detail: "Description", 
                description: "Les produits chimiques dangereux présentent des risques considérables lorsqu’ils ne sont pas utilisés ou stockés correctement. Cette formation vous permettra d'adopter une approche systématique de la sécurité et de la prévention des accidents dans la gestion des produits chimiques au travail."
            },
            { 
                detail: "Objectives", 
                description: (<p>• Connaitre les dangers et être en mesure d’évaluer les risques associés
                    à la manipulation des produits dangereux.<br/>
                    • Mettre en application les règles de stockage et de manutention des
                    produits en fonction des dangers et de leur nature.<br/>
                    • Connaitre les mesures de prévention et de protection propres à la
                    gestion des produits dangereux.</p>)
            },
            { 
                detail: "Duration", 
                description: "2 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: "27-28 / 05 / 2024"
            },
            { 
                detail: "Price", 
                description: "750 DT(HT) /participant"
            }
        ]
    }
];

const SanteEtSecuriteAuTravail = () => {
    const [selectedTraining, setSelectedTraining] = useState(null);
  
    const handleDetailsView = (training) => {
        console.log("Selected Training:", training); // Debugging
        setSelectedTraining(training);
    };
  
    const handleBackToCatalog = () => {
        console.log("Back to Catalog clicked"); // Debugging
        setSelectedTraining(null);
    };
  
   // Render the catalog view
const renderCatalog = () => (
    <div className="training-catalog">
        <h1 className="catalog-title">Formations - Santé et Sécurité au Travail</h1>
        <div className="training-list">
            {trainings.map((training) => (
                <div key={training.id} className="card-centered">
                    <h3 className="card-title">{training.title}</h3>
                    <p className="card-description">{training.description}</p>
                    <button onClick={() => handleDetailsView(training)} className="btn">
                        En savoir plus
                    </button>
                </div>
            ))}
        </div>
    </div>
);

  
    const renderDetails = () => (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
                Retour au Catalogue
            </button>
            
            <table className="details-table">
                <thead>
                    <tr>
                        <th colSpan="2" className="details-title">{selectedTraining.title}</th>
                    </tr>
                    <tr>
                        <th>Details</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedTraining.details.map((detail, index) => (
                        <tr key={index}>
                            <td>{detail.detail}</td>
                            <td>{detail.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
  
    return (
        <div className="min-vh-100">
            <Header />
            {selectedTraining ? renderDetails() : renderCatalog()}
            <Footer />
        </div>
    );
  };
  
  export default SanteEtSecuriteAuTravail;