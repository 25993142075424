import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './leadetshipetstrategie.css';

const LeadershipEtStrategie = () => {
  return (
    <div className="min-vh-100">
      <Header />
      <div className="card-container">
        <h1 className="catalog-title">Formations - Leadership et Stratégie </h1> {/* Add the title */}
        <div className="container">
          <div className="card mx-auto shadow card-centered text-center">
            <div className="card-body">
              <h2 className="card-title mb-3">Management Stratégique de l’Entreprise</h2>
              <p className="card-text">
                {/* You can add some content here */}
              </p>
              <Link to="/formation/leadership-strategie-details" className="btn btn-primary">
                En savoir plus
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LeadershipEtStrategie;
