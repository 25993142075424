import React from 'react';
import Header from '../components/Header'; // Import the Header component
import Footer from '../components/Footer'; // Import the Footer component

const SPCControlChartsPage = () => {
    const courseDetails = {
        id: 'spc-control-charts',
        title: 'SPC & Control Charts (MSP & Cartes de Contrôle)',
        details: [
            { 
                detail: "Description", 
                description: "La surveillance des processus à l’aide des cartes de contrôle est une méthode préventive qui permet de détecter les dérives du processus et d'anticiper l'apparition de produits non conformes en production." 
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                        • Connaitre les principes de la MSP et les notions statistiques associées (loi normale, échantillonnage, théorème de la limite centrale)<br />
                        • Calculer les limites de contrôle et de surveillance<br />
                        • Mettre en œuvre les cartes de contrôle pour les mesures variables et par attribut
                    </p>
                )
            },
            { 
                detail: "Durée", 
                description: " 3 jours"
            },
            { 
                detail: "Horaire", 
                description: "Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: (
                    <p>
                        • Session 1: 12-14 / 06 / 2024<br />
                        • Session 2: 02-04 / 12 / 2024
                    </p>
                )
            },
            { 
                detail: "prix", 
                description: "900 DT(HT) /participant"
            }
        ]
    };

    return (
        <>
            <Header />
            <div className="container py-5">
                <h2>{courseDetails.title}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Detail</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseDetails.details.map((item, index) => (
                            <tr key={index}>
                                <td><strong>{item.detail}</strong></td>
                                <td>{item.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Footer />
        </>
    );
};

export default SPCControlChartsPage;
