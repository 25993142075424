import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './leansixsigma.css'; // Import the updated CSS
import { FaCertificate } from 'react-icons/fa';
// Sample data for the trainings
const trainings = [
    {
        id: 'lean-introduction',
        name: "lean-introduction", 
        title: 'Lean Six Sigma Green Belt',
        details: [
            { 
                detail: "Description", 
                description: "Chef de projet d’amélioration de moyenne complexité, le Lean Six Sigma Green Belt met en œuvre la méthodologie DMAIC en vue d’améliorer un processus de manière conséquente. Il seconde les Black Belts dans la réalisation des projets complexes."
            },
            { 
                detail: "Objectives", 
                description: (<p>Connaitre les principes du Lean Six Sigma et la méthodologie DMAIC <br/>  Maitriser les outils statistiques et analytiques fondamentaux du Six Sigma <br/> Maitriser les outils fondamentaux du Lean Management  <br/>  Apprentissage du logiciel d’analyse statistique MINITAB  <br/> Réaliser un projet de niveau Green Belt en déployant le cycle DMAIC</p>)
            },
            { 
                detail: "Durée", 
                description: "10 jours (2+2+3+3) , Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions 2024 :", 
                description: (<p>Session 1: 15-16/04/2024 <br/> Session 2: 09-10/05/2024 <br/>  Session 3: 19-20/09/2024</p>)
            },
            { 
                detail: "Prix", 
                description: "3 500 DT(HT) / participant"
            },
            { 
                detail: "Certification", 
                description: "À la fin de cette formation, vous recevrez un certificat ."
            }
        ]
    },
    {
        id: 'lean-tools',
        title: 'Lean Six Sigma Black Belt',
        details: [
            { 
                detail: "Description", 
                description: "Chef de projet confirmé, le Lean Six Sigma Black Belt maitrise la méthode dans son ensemble. Il déploie et pilote des projets complexes et stratégiques et accompagne le déploiement de la méthodologie Lean Six Sigma."
            },
            { 
                detail: "Objectives", 
                description: (<p>• Maitriser les principes du Lean Six Sigma et la méthodologie DMAIC <br/> • Maitriser les outils statistiques et analytiques du Six Sigma <br/>  • Maitriser les outils d’amélioration continue du Lean Management <br/>•  Apprentissage du logiciel d’analyse statistique MINITAB Réaliser un projet de niveau Black Belt en déployant le cycle DMAIC <br/> • Etre capable d’encadrer des Lean Six Sigma Green Belts</p>)
            },
            { 
                detail: "Durée", 
                description: "20 jours (4+5+5+5+1), Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions 2024:", 
                description: "Module 1 , Module 2 , Module 3: Dates fixées selon la demande"
            },
            { 
                detail: "Prix", 
                description: "7 500DT(HT) / participant"
            },
            { 
                detail: "Certification", 
                description: "À l'issue de la formation, vous recevrez un certificat de réussite Lean Six Sigma Black Belt."
            }
        ]
    },
    {
        id: 'kaizen-event',
        title: 'Passage de Lean Six Sigma Green Belt à Black Belt',
        details: [
            { 
                detail: "Description", 
                description: "Vous êtes Lean Six Sigma Green Belt, vous souhaitez acquérir de nouveaux outils et un complément de formation sur la démarche DMAIC pour être en mesure de déployer des projets Lean Six Sigma Black Belt."
            },
            { 
                detail: "Objectives", 
                description: (<p>• Maitriser les principes du Lean Six Sigma et la méthodologie DMAIC<br/>
                    • Approfondir les outils statistiques et analytiques du Six Sigma<br/>
                    • Approfondir les outils d’amélioration continue du Lean Management<br/>
                    • Approfondir l’apprentissage du logiciel d’analyse statistique MINITAB<br/>
                    • Réaliser un projet de niveau Black Belt en déployant le cycle DMAIC<br/>
                    • Etre capable d’encadrer des Lean Six Sigma Green Belts</p>)
            },
            { 
                detail: "Durée", 
                description: "10 jours (2+2+3+3) Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions 2024 :", 
                description: "Module 1 , Module 2 , Module 3: Dates fixées selon la demande."
            },
            { 
                detail: "Prix", 
                description: "3 950 DT(HT) / participant"
            },
            { 
                detail: "Certification", 
                description: "À l'issue de cette formation, vous recevrez un certificat ."
            }
        ]
    },
];

const LeanSixSigma = () => {
    const [selectedTraining, setSelectedTraining] = useState(null);

    // Function to handle detail view
    const handleDetailsView = (training) => {
        setSelectedTraining(training);
    };

    // Function to go back to the catalog
    const handleBackToCatalog = () => {
        setSelectedTraining(null);
    };

    // Render the catalog view
    // Render the catalog view
const renderCatalog = () => (
    <div className="training-catalog">
        <h1 className="catalog-title">Formations - Lean Six Sigma</h1>
        <div className="training-list">
            {trainings.map((training) => (
                <div key={training.id} className="card-centered">
                    <h3 className="card-title">{training.title}</h3>
                    <p className="card-description">{training.description}</p>
                    <button onClick={() => handleDetailsView(training)} className="btn">
                        En savoir plus
                    </button>
                </div>
            ))}
        </div>
    </div>
);

    

    // Render the details view
    const renderDetails = () => {
        // Add a safety check for selectedTraining
        if (!selectedTraining) {
            return (
                <div className="container mt-5">
                    <p className="text-center">No training details available.</p>
                </div>
            );
        }

        return (
            <div className="container mt-5">
                <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
                    Retour au Catalogue
                </button>
                <table className="details-table">
                    <thead>
                        <tr>
                            <th colSpan="2" className="details-title">
                                {selectedTraining.title} 
                                <span className="certified-badge">
                                    <FaCertificate className="certificate-icon" />
                                    Formation certifiante
                                </span>
                            </th>
                        </tr>
                        <tr>
                            <th>Detail</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedTraining.details && selectedTraining.details.length > 0 ? (
                            selectedTraining.details.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.detail}</td>
                                    <td>{detail.description}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">No details available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="min-vh-100">
            <Header />
            {selectedTraining ? renderDetails() : renderCatalog()}
            <Footer />
        </div>
    );
};

export default LeanSixSigma;