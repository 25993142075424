import React from 'react';
import Header from '../components/Header'; // Import the Header component
import Footer from '../components/Footer'; // Import the Footer component


const BlackBeltPage = () => {
    return (
        <>
            <Header />
            <div className="container py-5">
                <h2>Passage de Lean Six Sigma Green Belt à Black Belt</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Detail</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Description</td>
                            <td>Chef de projet confirmé, le Lean Six Sigma Black Belt maitrise la
méthode dans son ensemble. Il déploie et pilote des projets complexes
et stratégiques et accompagne le déploiement de la méthodologie
Lean Six Sigma.</td>
                        </tr>
                        <tr>
                            <td>Objectives</td>
                            <td>
                            • Maitriser les principes du Lean Six Sigma et la méthodologie DMAIC.<br/>
                            • Maitriser les outils statistiques et analytiques du Six Sigma.<br/>
                            • Maitriser les outils d’amélioration continue du Lean Management.<br/>
                            • Apprentissage du logiciel d’analyse statistique MINITAB.<br/>
                            • Réaliser un projet de niveau Black Belt en déployant le cycle DMAIC.<br/>
                            • Etre capable d’encadrer des Lean Six Sigma Green Belts.<br/>
                            </td>
                        </tr>
                        <tr>
                            <td>Durée</td>
                            <td>10 jours (2+2+3+3) Horaire de la formation: de 08h30 à 16h00 (pauses incluses)</td>
                        </tr>
                        <tr>
                            <td>Dates des sessions 2024</td>
                            <td> Dates fixées selon la demande.</td>
                        </tr>
                        <tr>
                            <td>Prix</td>
                            <td> 3 950 DT(HT) / participant</td>
                        </tr>
                        <tr>
                            <td>Certification</td>
                            <td>À l'issue de cette formation, vous recevrez un certificat .</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Footer />
        </>
    );
};

export default BlackBeltPage;
