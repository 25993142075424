// src/pages/FormationPages/MethodeEtQualite.js
import React, { useState } from 'react';
import './methodedelaqualite.css'; // Import the CSS file
import Header from '../../components/Header'; // Import the Header component
import Footer from '../../components/Footer'; // Import the Footer component

// Sample data for the trainings
const trainings = [
  {
    id: 'methodology-sa9000',
    name: "methodology-sa9000", 
    title: 'AMDEC Processus (Process FMEA)',
    details: [
        { 
            detail: "Description", 
            description: "La méthode AMDEC Processus permet d’identifier, d’évaluer et de prévenir le risque des défaillances d’un processus industriel ou de service. Elle s’intègre parfaitement dans un processus d’amélioration continue et contribue à réduire les coûts de la non-qualité."
        },
        { 
            detail: "Objectives", 
            description: (<p>• Mettre en œuvre la méthode AMDEC Processus conformément au manuel AMDEC AIAG-VDA (2019) <br/> 
                        • Connaitre et savoir mettre en œuvre les étapes de la méthode AMDEC Processus <br/> 
                        • Identifier et évaluer les défaillances, et estimer la criticité des modes de défaillances / Définir un plan d’action et suivre son efficacité 
                         </p>)
        },
        { 
            detail: "Durée", 
            description: " 3 jours , Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Dates des sessions :", 
            description: (<p>• Session 1: 15-17 / 05 / 2024 <br/> 
                        • Session 2: 04-06 / 09 / 2024</p>)
        },
        { 
            detail: "Prix", 
            description: "900 DT(HT) /participant"
        }
    ]
},

{
  id: 'methodology-sa9000',
  name: "methodology-sa9000", 
  title: 'MSA (Analyse des Systèmes de Mesure)',
  details: [
      { 
          detail: "Description", 
          description: "La méthode MSA (analyse des systèmes de mesure) permet d’identifier les sources d’erreur de mesurage et de garantir l’exactitude et la précision des données de mesure."
      },
      { 
          detail: "Objectives", 
          description: (<p>• Comprendre comment la MSA s’intègre dans la maîtrise statistique des processus <br/> 
                      • Apprendre à analyser un système de mesure et notamment sa répétabilité et reproductibilité (études R&R selon la méthode ANOVA), biais et linéarité <br/> 
                      • Analyser un système de mesure à l’aide du logiciel MINITAB</p>)
      },
      { 
          detail: "Durée", 
          description: " 3 jours , Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
      },
      { 
          detail: "Dates des sessions :", 
          description: (<p>• Session 1: 03-05 / 06 / 2024 <br/> 
                      • Session 2: 02-04 / 10 / 2024</p>)
      },
      { 
          detail: "Prix", 
          description: "900 DT(HT) /participant"
      }
  ]
},

{
  id: 'quality-assurance',
  name: "quality-assurance", 
  title: 'DoE (Plans d’Expériences)',
  details: [
      { 
          detail: "Description", 
          description: "Les plans d'expériences constituent un outil de choix pour contribuer à la maîtrise rapide et fiable des produits et des processus en les rendant plus robustes face aux variabilités et obtenir ainsi une production stable et économique."
      },
      { 
          detail: "Objectives", 
          description: (<p>• Identifier et quantifier les paramètres influents des processus afin de les rendre plus robustes face aux variabilités <br/> 
                      • Maitriser les étapes de réalisation des plans d’expérience selon un plan factoriel complet ou un plan factoriel fractionnaire <br/> 
                      • Réaliser les plans d’expérience à l’aide du logiciel MINITAB</p>)
      },
      { 
          detail: "Durée", 
          description: "  3 jours , Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
      },
      { 
          detail: "Dates des sessions :", 
          description: (<p>• 26-28/06/2024</p>)
      },
      { 
          detail: "Prix", 
          description: "900 DT(HT) /participant"
      }
  ]
},

{
  id: 'taguchi-method',
  name: "taguchi-method", 
  title: 'Plans d’expériences: Méthode Taguchi',
  details: [
      { 
          detail: "Description", 
          description: "Les plans d’expériences selon la méthode Taguchi permettent d’améliorer les produits et les processus en les rendant aussi robustes et insensibles aux perturbations externes que possible (conception robuste)."
      },
      { 
          detail: "Objectives", 
          description: (<p>• Connaitre les enjeux de la conception robuste et les principes des plans d’expériences selon la méthode Taguchi <br/> 
                      • Etre capable de mettre en œuvre les plans de Taguchi pour améliorer les processus et les produits et les rendre plus robustes <br/> 
                      • Mettre en œuvre les plans de Taguchi à l’aide du logiciel MINITAB</p>)
      },
      { 
          detail: "Durée", 
          description: "3 jours , Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
      },
      { 
          detail: "Date de la session", 
          description: (<p>• 03-05 / 07 / 2024</p>)
      },
      { 
          detail: "Prix", 
          description: "900 DT(HT) /participant"
      }
  ]
},
{
  id: 'spc-control-charts',
  name: "spc-control-charts", 
  title: 'SPC & Control Charts (MSP & Cartes de Contrôle)',
  details: [
      { 
          detail: "Description", 
          description: "La surveillance des processus à l’aide des cartes de contrôle est une méthode préventive qui permet de détecter les dérives du processus et d'anticiper l'apparition de produits non conformes en production."
      },
      { 
          detail: "Objectives", 
          description: (<p>• Connaitre les principes de la MSP et les notions statistiques associées (loi normale, échantillonnage, théorème de la limite centrale) <br/> 
                      • Calculer les limites de contrôle et de surveillance <br/> 
                      • Mettre en œuvre les cartes de contrôle pour les mesures variables et par attribut</p>)
      },
      { 
          detail: "Durée", 
          description: "Durée: 3 jours , Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
      },
    
      { 
          detail: "Dates des sessions :", 
          description: (<p>• Session 1: 12-14 / 06 / 2024 <br/> • Session 2: 02-04 / 12 / 2024</p>)
      },
      { 
          detail: "Prix", 
          description: "900 DT(HT) /participant"
      }
  ]
},

{
  id: 'minitab-statistical-tools',
  name: "minitab-statistical-tools",
  title: 'Minitab et Outils Statistiques de la Qualité',
  details: [
      { 
          detail: "Description", 
          description: "Cette formation vous permettra l’apprentissage des outils statistiques de la qualité pour la maitrise des procédés et leur mise en œuvre à l’aide du puissant logiciel d’analyses statistiques MINITAB."
      },
      { 
          detail: "Objectives", 
          description: (<p>• Connaitre les principes de la maitrise statistique de la qualité <br/> 
                      • Apprentissage des principaux outils statistiques pour l’amélioration de la performance des processus et de la qualité des produits et des services <br/> 
                      • Mise en œuvre des outils statistiques de la qualité à l’aide de MINITAB</p>)
      },
      { 
          detail: "Durée", 
          description: "Durée: 8 jours (2+2+2+2) , Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
      },
      { 
          detail: "Dates des modules:", 
          description: (<p>• Module 1: 18-19 / 04 / 2024 <br/> 
                      • Module 2: 29-30 / 04 / 2024 <br/> 
                      • Module 3: 13-14 / 05 / 2024 <br/> 
                      • Module 4: 20-21 / 05 / 2024</p>)
      },
      { 
          detail: "Prix", 
          description: "1990 DT(HT) /participant"
      }
  ]
},
{
  id: 'iso9001-requirements',
  name: "iso9001-requirements",
  title: 'Exigences de la Norme ISO 9001:2015',
  details: [
      { 
          detail: "Description", 
          description: "Découvrez comment développer un système de management de la qualité efficace et efficient conforme à la norme ISO 9001:2015."
      },
      { 
          detail: "Objectives", 
          description: (<p>• Connaitre les principes du management de la qualité et comprendre l’évolution du concept de la qualité <br/> 
                      • Analyser et interpréter les exigences de la norme ISO 9001:2015 et savoir les appliquer à son entreprise <br/> 
                      • Connaitre les outils et méthodes nécessaires pour la mise en place d’un SMQ conforme aux exigences de la norme ISO 9001:2015</p>)
      },
      { 
          detail: "Durée", 
          description: "3 jours, Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
      },
      { 
          detail: "Dates des sessions", 
          description: (<p>• Session 1: 06-08 / 03 / 2024 <br/> • Session 2: 11-13 / 09 / 2024</p>)
      },
      { 
          detail: "Prix", 
          description: "850 DT(HT) /participant"
      }
  ]
},
{
  id: 'internal-auditor-iso9001',
  name: "internal-auditor-iso9001",
  title: 'Auditeur Interne ISO 9001:2015',
  details: [
      { 
          detail: "Description", 
          description: "La norme ISO 9001 positionne l’audit interne comme un outil de management incontournable pour la surveillance et la vérification de l’efficacité du système de management de la qualité d’un organisme."
      },
      { 
          detail: "Objectives", 
          description: (<p>• Acquérir la qualification « auditeur interne ISO 9001:2015 » <br/> 
                      • Maitriser les techniques d’audit et de communication en situation d’audit <br/> 
                      • Etre capable de réaliser des audits internes à haute valeur ajoutée <br/> 
                      • Savoir rédiger un rapport d’audit basé sur l’amélioration</p>)
      },
      { 
          detail: "Durée", 
          description: "3 jours , Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
      },
      { 
          detail: "Dates des sessions", 
          description: (<p>• Session 1: 24-26 / 05 / 2024 <br/> • Session 2: 23-25 / 10 / 2024</p>)
      },
      { 
          detail: "Prix", 
          description: "850 DT(HT) /participant"
      }
  ]
},


  { 
      "id": "iatf-16949-requirements",
      "name": "iatf-16949-requirements",
      "title": "Exigences de la Norme IATF 16949:2016",
      "details": [
          { 
              "detail": "Description", 
              "description": "Les constructeurs automobiles mondiaux sont unanimement d’accord pour faire appliquer par leurs sous-traitants et fournisseurs les exigences de l’IATF 16949."
          },
          { 
              "detail": "Objectives", 
              "description": (<p>• S’approprier les nouvelles exigences de l'ISO 9001:2015 et les nouvelles exigences spécifiques automobiles de l’IATF 16949:2016 <br/> • Analyser et interpréter les exigences de l’ISO 9001:2015 et de l’IATF 16949:2016 et savoir les appliquer à son entreprise <br/> • Connaitre les outils et méthodes nécessaires pour la mise en place d’un SMQ conforme aux exigences de la norme IATF 9001:2016</p>)
          },
          { 
              "detail": "Durée", 
              "description": "3 jours , Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
          },
          { 
              "detail": "Dates des sessions", 
              "description": (<p>• 22-24 / 05 / 2024</p>)
          },
          { 
              "detail": "Prix", 
              "description": "900 DT(HT) /participant"
          }
      ]
  },
  { 
      "id": "internal-auditor-iatf16949",
      "name": "internal-auditor-iatf16949",
      "title": "Auditeur Interne IATF 16949:2016",
      "details": [
          { 
              "detail": "Description", 
              "description": "Les constructeurs automobiles mondiaux sont unanimement d’accord pour faire appliquer par leurs sous-traitants et fournisseurs les exigences de l’IATF 16949. Cette formation vous permettra d’acquérir la qualification d’auditeur interne IATF 16949."
          },
          { 
              "detail": "Objectives", 
              "description": (<p>• Acquérir la qualification « auditeur interne IATF 16949:2016 » <br/> • Maitriser les techniques d’audit et de communication en situation d’audit <br/> • Etre capable de réaliser des audits internes à haute valeur ajoutée <br/> • Savoir rédiger un rapport d’audit basé sur l’amélioration</p>)
          },
          { 
              "detail": "Durée", 
              "description": "3 jours , Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
          },
          
          { 
              "detail": "Dates des sessions", 
              "description": (<p>• 25-27 / 09 / 2024</p>)
          },
          { 
              "detail": "Prix", 
              "description": "900 DT(HT) /participant"
          }
      ]
  },
  { 
      "id": "ppap-training",
      "name": "ppap-training",
      "title": "PPAP (Production Part Approval Process)",
      "details": [
          { 
              "detail": "Description", 
              "description": "PPAP définit les exigences des constructeurs automobiles vis-à-vis de leurs fournisseurs pour l’homologation initiale des composants et de leurs procédés de production."
          },
          { 
              "detail": "Objectives", 
              "description": (<p>• Connaitre les objectifs et les phases du processus PPAP <br/> • Connaitre les éléments du processus PPAP et être en mesure de le mettre en œuvre <br/> • Comprendre les requis applicables pour la soumission du dossier relié au PPAP des pièces automobiles</p>)
          },
          { 
              "detail": "Durée", 
              "description": "3 jours , Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
          },
          
          { 
              "detail": "Dates des sessions", 
              "description": (<p>• 19-21 / 06 / 2024</p>)
          },
          { 
              "detail": "Prix", 
              "description": "900 DT(HT) /participant"
          }
      ]
  },
  { 
      "id": "apqp-training",
      "name": "apqp-training",
      "title": "APQP (Advanced Product Quality Planning)",
      "details": [
          { 
              "detail": "Description", 
              "description": "APQP définit les activités de planification pour le développement d’un produit afin de satisfaire les exigences des clients dans le secteur automobile."
          },
          { 
              "detail": "Objectives", 
              "description": (<p>• Connaitre les objectifs, les différentes phases et les éléments de la démarche APQP <br/> • Comprendre le plan de contrôle et ses différents stade d’élaboration <br/> • Comprendre les notions aux différents outils utilisables dans le cadre de la démarche APQP</p>)
          },
          { 
              "detail": "Durée", 
              "description": "3 jours , Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
          },
          { 
              "detail": "Dates des sessions", 
              "description": (<p>• 18-20 / 09 / 2024</p>)
          },
          { 
              "detail": "Prix", 
              "description": "900 DT(HT) /participant"
          }
      ]
  },
  { 
      "id": "vda5-measurement-process",
      "name": "vda5-measurement-process",
      "title": "Analyse des Processus de Mesure (VDA 5)",
      "details": [
          { 
              "detail": "Description", 
              "description": "Le référentiel allemand VDA 5 définit une méthode pour la validation des processus de mesure qui peut être considérée comme une alternative à la méthode d’analyse des systèmes de mesure de l’AIAG."
          },
          { 
              "detail": "Objectives", 
              "description": (<p>• Analyser et étudier la dispersion R&R court terme <br/> • Déterminer la capabilité d’un système de mesure <br/> • Maitriser l’évaluation du biais et de linéarité d’un système de mesure <br/> • Maitriser l’évaluation d’un système de contrôle de type attribut</p>)
          },
          { 
              "detail": "Durée", 
              "description": "3 jours , Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
          },
          { 
              "detail": "Dates des sessions", 
              "description": (<p>• 26-28 / 06 / 2024</p>)
          },
          { 
              "detail": "Prix", 
              "description": "900 DT(HT) /participant"
          }
      ]
  }, 
  { 
    "id": "process",
    "name": "vda5-measurement-process",
    "title": "Audit de Processus selon la Norme VDA 6.3",
    "details": [
        { 
            "detail": "Description", 
            "description": "Les constructeurs automobiles allemands demandent l’application des standards VDA comme exigences additionnelles. La base de l’audit de processus est le VDA 6.3 part B qui inclut une évaluation de tous les processus et procédures de la production.."
        },
        { 
            "detail": "Objectives", 
            "description": (<p>• Comprendre les exigences du VDA 6.3 de P1 à P7 <br/> • Savoir utiliser le questionnaire lors de la préparation de l’audit. <br/> • Comprendre les règles de cotation et savoir évaluer correctement. <br/> • Comprendre comment renseigner le rapport d’audit</p>)
        },
        { 
            "detail": "Durée", 
            "description": "3 jours , Horaire de la formation de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            "detail": "Dates des sessions", 
            "description": (<p>•  17-19 / 04 / 2024</p>)
        },
        { 
            "detail": "Prix", 
            "description": "900 DT(HT) /participant"
        }
    ]
}


    
    
    
];
const MethodeEtQualite = () => {
    const [selectedTraining, setSelectedTraining] = useState(null);
  
    // Function to handle detail view
    const handleDetailsView = (training) => {
      setSelectedTraining(training);
    };
  
    // Function to go back to the catalog
    const handleBackToCatalog = () => {
      setSelectedTraining(null);
    };
  
    // Render the catalog view
    const renderCatalog = () => (
      <div className="container mt-5">
       

        <div className="row">
        <h1 className="catalog-title">Formations - Méthodes de la Qualité</h1> {/* Add the title */}
          {trainings.map((training) => (
            <div className="col-md-3 mb-4" key={training.id}>
              <div className="training-item shadow text-center">
                <h2 className="card-title">{training.title}</h2>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => handleDetailsView(training)}
                    className="btn btn-primary"
                  >
                  En savoir plus
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  
    // Render the details view
    const renderDetails = () => (
      <div className="container mt-5">
        <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
        Retour au Catalogue
        </button>
        
        <table className="details-table">
          <thead>
            <tr>
              <th colSpan="2" className="details-title">{selectedTraining.title}</th>
            </tr>
            <tr>
              <th>Detail</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {selectedTraining.details.map((detail, index) => (
              <tr key={index}>
                <td>{detail.detail}</td>
                <td>{detail.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  
    return (
      <div>
        <Header />
        {selectedTraining ? renderDetails() : renderCatalog()}
        <Footer />
      </div>
    );
  };
  
  export default MethodeEtQualite;