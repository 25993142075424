import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import about from '../Images/Cns (1).jpg'; // Corrected image path

import './consulting.css'; // Import custom CSS

const Consulting = () => {
    useEffect(() => {
        document.title = "Consulting - Lean Management Institute"; // Set the page title
    }, []); // Empty dependency array ensures it runs once when the component mounts

    return (
        <>
            <Header page="consulting" />
            <div className="processus-certification-container">
                

                {/* Image Section */}
                <div className="certification-image-container">
                    <img src={about} alt="Consulting" className="certification-image" />
                </div>
                {/* Page Title */}
                <h1 className="page-title">Conseil et accompagnement</h1>
                {/* Consulting Text */}
                <div className="consulting-text">
                    <p className="intro-text">
                        Le déploiement d'une démarche d'excellence opérationnelle efficace et durable peut s'avérer un véritable défi pour les entreprises et les organisations. 
                        En effet, ceci implique l'élaboration et la mise en œuvre de principes, de systèmes et d'outils spécifiques, et nécessite souvent une transformation culturelle 
                        (établissement d'un nouvel état d'esprit).
                    </p>
                    <p className="emphasis-text">
                        Dans cette optique, Lean Management Institute propose un conseil / accompagnement / coaching à haute valeur ajoutée dans les domaines suivants:
                    </p>
                    <ul className="list-unstyled">
                        <li className="list-item">• Mise en place et conduite de chantiers Lean</li>
                        <li className="list-item">• Transformation Lean / déploiement de la démarche Lean à l'échelle de l'entreprise</li>
                        <li className="list-item">• Mise en place et conduite de projets Six Sigma / DMAIC / Maîtrise statistique des procédés / Outils statistiques de la qualité</li>
                        <li className="list-item">• Déploiement de la démarche Six Sigma à l'échelle de l'entreprise</li>
                        <li className="list-item">• Déploiement des méthodes agiles et Scrum pour la gestion des projets et le développement de nouveaux produits / services</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Consulting;
