import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header'; // Adjusted path to the Header component
import Footer from '../components/Footer'; // Adjusted path to the Footer component
import './evenement.css';
// Import images for recent and old events
import eventImage1 from '../Images/Image1.jpg';
import eventImage2 from '../Images/Image2.png';
import eventImage3 from '../Images/Image3.png';
import eventImage4 from '../Images/Image4.png';
import oldEventImage1 from '../Images/Image5.png';
import oldEventImage2 from '../Images/Image6.png';
import oldEventImage3 from '../Images/Image7.png';
import oldEventImage4 from '../Images/Image8.png';
import oldEventImage5 from '../Images/Image9.png';
import oldEventImage6 from '../Images/Image10.png';
import oldEventImage7 from '../Images/Image12.png';
import oldEventImage8 from '../Images/Image13.png';
import oldEventImage9 from '../Images/Image14.png';
import oldEventImage10 from '../Images/Image15.png';
import oldEventImage11 from '../Images/Image20.png';
import oldEventImage12 from '../Images/Image16.png';
import oldEventImage13 from '../Images/Image17.png';
import oldEventImage14 from '../Images/Image18.png';
import oldEventImage15 from '../Images/Image19.png'
// All the events data
// eventlist.js
export const events = [

  {
    id: 1,
    title: (
      <div>
        <span className="eventTitle">Démarrage d’une session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">à partir du 12 décembre 2024</span>
      </div>
    ),
    date: {
      duration: "10 jours",
      firstModuleDate: "12 décembre 2024"
    },
    location: "Tunis",
    venue: "Hôtel 5 ou 4 étoiles à Tunis",
    contact: {
      phone: "21 630 851",
      email: "contact@lmi.training"
    },
    photo: eventImage1, // Path to the event image
  },
  { 
    id: 2, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Automotive: Présentation des projets Lean Six Sigma Green Belt et remise des certificats</span>
        <span className="eventDate">- 6 novembre 2024</span>
      </div>
    ),
    photo: eventImage2
  },
  { 
    id: 3, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Automotive: Démarrage de la session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">- 4 juillet 2024</span>
      </div>
    ),
    photo: eventImage3
  },
  { 
    id: 4, 
    title: (
      <div>
        <span className="eventTitle">Groupe SOPAL: Démarrage de la session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">- 15 avril 2024</span>
      </div>
    ),
    photo: eventImage4
  },
  { 
    id: 5, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Group: Présentation des projets Lean Six Sigma Black Belt et remise des certificats</span>
        <span className="eventDate">- 15 novembre 2023</span>
      </div>
    ),
    photo: oldEventImage1
  },
  { 
    id: 6, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Group: Démarrage de la session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">- 29 mai 2023</span>
      </div>
    ),
    photo: oldEventImage2
  },
  { 
    id: 7, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Automotive: Présentation des projets Lean Six Sigma Green Belt et remise des certificats</span>
        <span className="eventDate">- 13 septembre 2022</span>
      </div>
    ),
    photo: oldEventImage3
  },
  { 
    id: 8, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Automotive: Programme de formation pour ingénieurs qualité</span>
        <span className="eventDate">- 19 janvier 2022</span>
      </div>
    ),
    photo: oldEventImage4
  },
  { 
    id: 9, 
    title: (
      <div>
        <span className="eventTitle">Groupe Rose Blanche: Démarrage de la session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">- 6 janvier 2022</span>
      </div>
    ),
    photo: oldEventImage5
  },
  { 
    id: 10, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Automotive: Présentation des projets Lean Six Sigma Black Belt et remise des certificats</span>
        <span className="eventDate">- 13 octobre 2021</span>
      </div>
    ),
    photo: oldEventImage6
  },
  { 
    id: 11, 
    title: (
      <div>
        <span className="eventTitle">Dräxlmaier: Présentation des projets Lean Six Sigma Green Belt et remise des certificats</span>
        <span className="eventDate">- 15 décembre 2021</span>
      </div>
    ),
    photo: oldEventImage7
  },
  { 
    id: 12, 
    title: (
      <div>
        <span className="eventTitle">Session de formation Gestion de Projet Agile / Méthodologie Scrum</span>
        <span className="eventDate">- 16 mars 2020</span>
      </div>
    ),
    photo: oldEventImage8
  },
  { 
    id: 13, 
    title: (
      <div>
        <span className="eventTitle">Dräxlmaier: Démarrage de la session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">- 24 janvier 2020</span>
      </div>
    ),
    photo: oldEventImage9
  },
  { 
    id: 14, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Automotive: Présentation des projets Lean Six Sigma Green Belt et remise des certificats</span>
        <span className="eventDate">- 5 juillet 2019</span>
      </div>
    ),
    photo: oldEventImage10
  },
  { 
    id: 15, 
    title: (
      <div>
        <span className="eventTitle">Bic World: Démarrage de la session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">- 17 octobre 2019</span>
      </div>
    ),
    photo: oldEventImage11
  },
  { 
    id: 16, 
    title: (
      <div>
        <span className="eventTitle">Safran Aerospace: Présentation des projets Lean Six Sigma Black Belt et Green Belt et remise des certificats</span>
        <span className="eventDate">- 18 février 2019</span>
      </div>
    ),
    photo: oldEventImage12
  },
  { 
    id: 17, 
    title: (
      <div>
        <span className="eventTitle">Marquardt Automotive: Démarrage de la session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">- 28 septembre 2018</span>
      </div>
    ),
    photo: oldEventImage13
  },
  { 
    id: 18, 
    title: (
      <div>
        <span className="eventTitle">Teleperformance Group: Démarrage de la session de formation Lean Six Sigma Green Belt</span>
        <span className="eventDate">- 24 juillet 2018</span>
      </div>
    ),
    photo: oldEventImage14
  },
  { 
    id: 19, 
    title: (
      <div>
        <span className="eventTitle">Ennakl Automobiles: Remise des certificats Lean Six Sigma Black Belt</span>
        <span className="eventDate">- 18 avril 2017</span>
      </div>
    ),
    photo: oldEventImage15
  }
];



const EventList = () => {
  return (
    <div>
      <Header /> {/* Add the header component */}
      
      <div className="event-list-container">
        <ul className="event-list">
          {events.map((event, index) => (
            <li key={event.id} className="event-item">
              <Link to={`/event/${event.id}`} className="event-link">
                <div className="event-title">
                  <h3>{event.title}</h3>
                  {/* Apply consistent styling for all events */}
                  {event.date && event.date.start && (
                    <span className="event-date">
                      {event.date.start}
                    </span>
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <Footer /> {/* Add the footer component */}
    </div>
  );
};

export default EventList;


