import React from 'react';
import Header from '../../components/Header'; // Adjust the path as necessary
import Footer from '../../components/Footer'; // Adjust the path as necessary
import { FaQuestionCircle } from 'react-icons/fa'; // Importing an icon
import './faq.css'; // Import the CSS file for styling

const FAQ = () => {
  const faqs = [
    {
      question: "Quelle certification viser: Green Belt ou Black Belt?",
      answer: "Cela dépend de la complexité des projets que vous aurez à mener et du niveau de connaissance des outils Lean Six Sigma requis.",
    },
    {
      question: "Est-il possible de devenir Black Belt sans être Green Belt?",
      answer: "Oui, il est possible de passer directement par une formation Black Belt sans avoir suivi préalablement une formation Green Belt.",
    },
    {
      question: "Pourquoi les formations Lean Six Sigma sont-t-elles réparties en modules séparés?",
      answer: "Les journées de formation sont réparties en modules séparés de quelques semaines pour vous permettre de consolider vos acquis théoriques et appliquer les méthodes et outils sur le terrain.",
    },
    {
      question: "Quels sont les critères de certification?",
      answer: "Assiduité et participation active, Réussite de l’examen final (70% au QCM), Rapport sur le déploiement d’un projet Lean Six Sigma dans un délai maximum d’un an.",
    },
    {
      question: "Les formations et certifications Lean Six Sigma du LMI sont elles accréditées?",
      answer: "Le contenu de nos formations Lean Six Sigma est aligné sur le BOK de l’IASSC (International Association for Six Sigma Certification). Nous proposons en option une certification internationale indépendante (tierce partie) délivrée par l’IASSC.",
    },
  ];

  return (
    <>
      <Header page="faq" />
      <div className="faq-container">

        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question-container">
                <FaQuestionCircle className="faq-icon" />
                <h2 className="faq-question">{faq.question}</h2>
              </div>
              <p className="faq-answer">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
