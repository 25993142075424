import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Images/logo.png';  // Import the logo image
import './header.css';

const Header = (props) => {
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
      {/* Brand Logo with Image */}
      <Link to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
        {/* Use the imported logo */}
        <img src={logo} alt="Logo" className="logo" style={{ height: '40px', width: 'auto' }} />
      </Link>

      {/* Navbar Toggle Button (for smaller screens) */}
      <button 
        type="button" 
        className="navbar-toggler me-4" 
        data-bs-toggle="collapse" 
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon" />
      </button>

      {/* Navbar Links */}
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          {/* Catalogue */}
          <Link 
            to="/Catalogue" 
            className={props.page === "CertiBase" ? "nav-item nav-link active" : "nav-item nav-link"}
          >
            Formations
          </Link>

          {/* Certi-Base */}
          <Link 
            to="/certibase" 
            className={props.page === "CertiBase" ? "nav-item nav-link active" : "nav-item nav-link"}
          >
            CertiBase
          </Link>

          {/* Consulting */}
          <Link 
            to="/consulting" 
            className={props.page === "Consulting" ? "nav-item nav-link active" : "nav-item nav-link"}
          >
            Consulting
          </Link>

          {/* Ressources Dropdown Menu */}
          <div className="nav-item dropdown">
            <a 
              href="/" 
              className={props.page === "Ressources" ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle"} 
              data-bs-toggle="dropdown"
            >
              Ressources
            </a>
            <div className="dropdown-menu fade-down m-0">
              <Link to="/ressources/faq" className="dropdown-item">FAQ</Link>
              <Link to="/ressources/processus-certification" className="dropdown-item">Processus de Certification</Link>
              <Link to="/ressources/financement" className="dropdown-item">Financement</Link>
             
            </div>
          </div>

          {/* Evenements */}
          <Link 
            to="/evenements" 
            className={props.page === "Evenements" ? "nav-item nav-link active" : "nav-item nav-link"}
          >
            Evenements
          </Link>

          {/* Contacter Nous */}
          <Link 
            to="/contact" 
            className={props.page === "Contact" ? "nav-item nav-link active" : "nav-item nav-link"}
          >
            Contact
          </Link>

          {/* Télécharger Programme Formations Dropdown */}
          <div className="nav-item dropdown">
            <a 
              href="/" 
              className="nav-link dropdown-toggle" 
              id="dropdownMenuButton" 
              data-bs-toggle="dropdown" 
              aria-expanded="false"
            >
              {/* Splitting the title into two lines */}
              Télécharger 
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {/* Dynamically change the year */}
              <a className="dropdown-item" href={`/pdfs/LMI-FORMATIONS-${currentYear}.pdf`} target="_blank" rel="noopener noreferrer">
                Programmes <br /> de formation <br /> {currentYear}
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
