// src/pages/FormationPages/ProjectManagementIntroductionPage.js
import React from 'react';
import Header from '../components/Header'; // Import the Header component
import Footer from '../components/Footer'; // Import the Footer component


const ProjectManagementIntroductionPage = () => {
    return (
        <>
            <Header />
            <div className="container py-5">
                <h2>Gestion de Projet Agile: Méthode Scrum</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Detail</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Description</td>
                            <td>Les méthodes Agiles, et en particulier Scrum, sont devenues le nouveau standard de gestion des projets de développement. Elles permettent de mieux maîtriser les délais, les coûts tout en augmentant la qualité des produits.</td>
                        </tr>
                        <tr>
                            <td>Objectives</td>
                            <td>
                                • Connaître les principes, les valeurs et les avantages de la méthode Scrum <br />
                                • Connaître les rôles de la méthode Scrum et leurs responsabilités associées <br />
                                • Etre en mesure de développer le backlog du produit et de planifier les releases du produit <br />
                                • Etre en mesure de mettre efficacement en oeuvre la planification, la réalisation, la revue et la rétrospective des sprints
                            </td>
                        </tr>
                        <tr>
                            <td>Durée</td>
                            <td>4 jours ,  Horaire de la formation: de 08h30 à 16h00 (pauses incluses)</td>
                        </tr>
                        <tr>
                            <td>Dates des sessions</td>
                            <td>Session 1: 23-26 / 04 / 2024 <br/>Session 2: 19-22 / 11 / 2024</td>
                        </tr>
                        <tr>
                            <td>Prix</td>
                            <td>1 200 DT(HT) / participant</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Footer />
        </>
    );
};

export default ProjectManagementIntroductionPage;
