import React from 'react';
import './financement.css'; // Import the CSS file for styles
import financementImage from '../../Images/Fin.jpg'; // Update the path to your image
import Header from '../../components/Header'; // Adjust the path as necessary
import Footer from '../../components/Footer'; // Adjust the path as necessary

const Financement = () => {
  return (
    <div className="financement-container">
      
      <Header /> {/* Add Header component here */}
      <div className="financement-content-wrapper">
        <div className="financement-content">
            
          <img src={financementImage} alt="Financement" className="financement-image" />
           {/* Page Title */}
           <h1 className="page-title">Financement</h1>
          <p>
            Le système actuel de financement de la formation continue en Tunisie repose sur trois instruments suivants:
          </p>
          <h5>1. Crédit d'impôt</h5>
          <p>
            L'avance sur la taxe de formation professionnelle est un crédit fiscal dont le montant équivaut au maximum à 60% de la taxe due au titre de l'année précédant l'année de réalisation des actions de formation.
          </p>
          <h5>2. Droits de tirage</h5>
          <p>
            Les droits de tirage permettent aux entreprises économiques privées de bénéficier d'un financement direct de la part de l'État au titre des actions et des activités de formation réalisées au profit de leurs agents, en contrepartie de leur contribution à la formation initiale (apprentissage, formation en alternance et stages obligatoires).
          </p>
          <h5>3. Article 39</h5>
          <p>
            L'article 39 permet de financer les programmes de formation du personnel des entreprises réalisant un investissement technologique.
          </p>
          <p>
            Pour de plus amples informations sur les mécanismes de financement de la formation continue en Tunisie, les entreprises souhaitant en bénéficier sont priées de contacter le Centre National de Formation Continue et de Promotion Professionnelle.
          </p>
        </div>
      </div>
      <Footer /> {/* Add Footer component here */}
    </div>
  );
};

export default Financement;
