// src/pages/FormationPages/LeanManufacturingPage.js
import React from 'react';
import Header from '../components/Header'; // Import the Header component
import Footer from '../components/Footer'; // Import the Footer component
// Optional CSS for styling the page

const LeanManufacturingPage = () => {
    return (
        <>
            <Header />
            <div className="container py-5">
                <h2>Lean Manufacturing</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Detail</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Description</td>
                            <td>Le Lean est une démarche visant à générer une valeur ajoutée maximale au moindre coût. Le Lean se base sur la réduction et l’élimination systématiques des gaspillages, ainsi que sur l’impliquation de l’ensemble du personnel.</td>
                        </tr>
                        <tr>
                            <td>Objectives</td>
                            <td>Assimilation des principes fondamentaux et des 3 piliers du Lean. <br/> Connaitre les enjeux et les avantages de la démarche Lean.<br/> Apprentissage des outils et méthodes du Lean Manufacturing.<br/> Établir des indicateurs et un tableau de bord Lean afin d’être en mesure de piloter la démarche Lean.</td>
                        </tr>
                        <tr>
                            <td>Durée</td>
                            <td>Horaire de la formation: de 08h30 à 16h00 (pauses incluses)</td>
                        </tr>
                        <tr>
                            <td>Dates des sessions</td>
                            <td>Session 1: 06-10 / 05 / 2024; Session 2: 23-27 / 09 / 2024</td>
                        </tr>
                        <tr>
                            <td>Prix</td>
                            <td>1 500 DT(HT)/participant</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Footer />
        </>
    );
};

export default LeanManufacturingPage;
