import React from 'react';
import { useParams } from 'react-router-dom';
import { events } from './eventlist'; // Import the events data
import Header from '../components/Header'; // Adjust path
import Footer from '../components/Footer'; // Adjust path
import './eventdetail.css'; // Add styles for the event details page

const EventDetail = () => {
  // Get the event ID from the URL params
  const { id } = useParams();

  // Find the event by ID
  const event = events.find(event => event.id === parseInt(id));

  if (!event) {
    return <div>Event not found</div>;
  }

  return (
    <div>
      <Header />
      
      <div className="event-detail-container">
        <h1>{event.title}</h1>
        
        {/* Display the event photo */}
        <div className="event-photo-container">
          <img src={event.photo} alt={event.title} className="event-photo" />
        </div>
        
        {/* Conditionally display location */}
        {event.location && <p><strong>Localisation:</strong> {event.location}</p>}
        
        {/* Display venue if available */}
        {event.lieu && <p><strong>Lieu:</strong> {event.lieu}</p>}

        {/* Add more details if available */}
        {event.contact && (
          <div>
            <p><strong>Contact:</strong></p>
            <p>Téléphone: {event.contact.phone}</p>
            <p>Email: <a href={`mailto:${event.contact.email}`}>{event.contact.email}</a></p>
          </div>
        )}

        <p>{event.description}</p>
      </div>

      <Footer />
    </div>
  );
};

export default EventDetail;
