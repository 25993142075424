// src/pages/FormationPages/LeanSixSigmaPage.js
import React from 'react';
import Header from '../components/Header'; // Import the Header component
import Footer from '../components/Footer'; // Import the Footer component


const courseDetails = {
    id: 'lean-introduction',
    name: "lean-introduction", 
    title: 'Lean Six Sigma Green Belt',
    details: [
        { 
            detail: "Description", 
            description: "Chef de projet d’amélioration de moyenne complexité, le Lean Six Sigma Green Belt met en œuvre la méthodologie DMAIC en vue d’améliorer un processus de manière conséquente. Il seconde les Black Belts dans la réalisation des projets complexes." 
        },
        { 
            detail: "Objectives", 
            description: (
                <p>
                    • Connaitre les principes du Lean Six Sigma et la méthodologie DMAIC<br />
                    • Maitriser les outils statistiques et analytiques fondamentaux du Six Sigma<br />
                    • Maitriser les outils fondamentaux du Lean Management<br />
                    • Apprentissage du logiciel d’analyse statistique MINITAB<br />
                    • Réaliser un projet de niveau Green Belt en déployant le cycle DMAIC
                </p>
            )
        },
        { 
            detail: "Durée", 
            description: "10 jours (2+2+3+3) Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        {
            detail: "Dates des sessions 2024",
            description: (
                <p>
                    • Début du premier module de la Session 1 : 15-16/04/2024<br />
                    • Début du premier module de la Session 2 : 09-10/05/2024<br />
                    • Début du premier module de la Session 3 : 19-20/09/2024
                </p>
            )
        },
        
        { 
            detail: "Prix", 
            description: " 3 500 DT(HT) /participant"
        }, 
        {
            detail : "Certification",
            description:"À l'issue de cette formation, vous recevrez un certificat "
        }
        
    ]
};

const LeanSixSigmaPage = () => {
    return (
        <>
            <Header />
            <div className="container py-5">
                <h2>{courseDetails.title}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Detail</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseDetails.details.map((item, index) => (
                            <tr key={index}>
                                <td>{item.detail}</td>
                                <td>{item.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Footer />
        </>
    );
};

export default LeanSixSigmaPage;
