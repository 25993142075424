import React, { useState } from 'react';
import Header from '../components/Header'; // Adjust the path as necessary
import Footer from '../components/Footer'; // Adjust the path as necessary
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA component
import axios from 'axios'; // Import Axios for making API requests

const ContactForm = () => {
    const [captchaValue, setCaptchaValue] = useState(null); // Store reCAPTCHA value
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value); // Set the reCAPTCHA response value
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate reCAPTCHA
        if (!captchaValue) {
            alert('Please complete the CAPTCHA');
            return;
        }

        // Send the form data and captcha response to the server
        try {
            const response = await axios.post('https://lmi.training/api/contact', {
                ...formData,
                captchaValue: captchaValue // Include CAPTCHA response in the request
            });

            // Handle success
            console.log('Form submitted successfully:', response.data);
            alert('Your message has been sent!');
        } catch (error) {
            // Handle error
            console.error('Error sending message:', error);

            // Detailed error logging to console for debugging
            if (error.response) {
                console.error('Response error:', error.response.data); // Response body from the server
                console.error('Response status:', error.response.status); // Status code
                console.error('Response headers:', error.response.headers); // Response headers
            } else if (error.request) {
                console.error('Request error:', error.request); // If no response was received
            } else {
                console.error('Error message:', error.message); // Other errors
            }

            alert('There was an error sending your message.');
        }
    };

    return (
        <div>
            {/* Header Component */}
            <Header />

            {/* Contact Form */}
            <div className="container py-5">
                <h2>Contactez Nous</h2>
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Your Name"
                                        />
                                        <label htmlFor="name">Nom et Prénom</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Your Email"
                                        />
                                        <label htmlFor="email">Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            placeholder="Subject"
                                        />
                                        <label htmlFor="subject">Sujet</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            placeholder="Your Message"
                                            style={{ height: '150px' }}
                                        ></textarea>
                                        <label htmlFor="message">Votre Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <ReCAPTCHA
                                        sitekey="6LfvCoEqAAAAAO-R4SSbeKJBGtMARYC4vfZHq63W" // Replace with your actual site key
                                        onChange={handleCaptchaChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary w-100 py-3"
                                     style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}>
                                        
                                        Envoyer
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Footer Component */}
            <Footer />
        </div>
    );
};

export default ContactForm;
