// src/components/LeanManufacturing.js
import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './leanmanifacturing.css'; 

const trainings = [
  {
    id: '1',
    title: 'Lean Manufacturing',
    description: "Le Lean est une démarche visant à générer une valeur ajoutée maximale au moindre coût. Le Lean se base sur la réduction et l’élimination systématiques des gaspillages, ainsi que sur l’impliquation de l’ensemble du personnel.",
    details: [
        { 
            detail: "Description", 
            description: "Le Lean est une démarche visant à générer une valeur ajoutée maximale au moindre coût. Le Lean se base sur la réduction et l’élimination systématiques des gaspillages, ainsi que sur l’impliquation de l’ensemble du personnel."
        },
        { 
            detail: "Objectives", 
            description: (
                <p>
                   • Assimilation des principes fondamentaux et des 3 piliers du Lean <br />
                   • Connaitre les enjeux et les avantages de la démarche Lean <br />
                   • Apprentissage des outils et méthodes du Lean Manufacturing <br />
                   • Établir des indicateurs et un tableau de bord Lean afin d’être en mesure de piloter la démarche Lean
                </p>
            )
        },
        { 
            detail: "Durée", 
            description: " 5 jours , Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Dates des sessions:", 
            description: "Session 1: 06-10 / 05 / 2024 , Session 2: 23-27 / 09 / 2024"
        },
        { 
            detail: "Prix", 
            description: "1 500 DT(HT)/participant"
        }
    ]
  },
    {
      id: '2',
      title: 'Convergence Lean et ISO 9001',
      description: "Lean Management et ISO 9001 sont deux démarches complémentaires qui, lorsqu'elles sont combinées, peuvent être une force puissante pour l'amélioration durable des performances.",
      details: [
          { 
              detail: "Description", 
              description: "Lean Management et ISO 9001 sont deux démarches complémentaires qui, lorsqu'elles sont combinées, peuvent être une force puissante pour l'amélioration durable des performances."
          },
          { 
              detail: "Objectives", 
              description: (
                  <p>
                      • Connaitre les principaux outils et méthodes du Lean Management. <br />
                      • Comprendre les synergies entre Lean Management et ISO 9001. <br />
                      • Établir des indicateurs et un tableau de bord basés sur la démarche Lean et en harmonie avec les exigences de la norme ISO 9001.
                  </p>
              )
          },
          { 
              detail: "Durée", 
              description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
          },
          { 
              detail: "Dates des sessions", 
              description: "Session 1: 29-31 / 05 / 2024, Session 2: 04-06 / 11 / 2024"
          },
          { 
              detail: "Prix", 
              description: "900 DT(HT) /participant"
          }
      ]
    },
    {
      id: '3',
    title: 'Hoshin Kanri',
    description: "Hoshin Kanri est une démarche de déploiement de stratégie pour fédérer le personnel autour de la vision stratégique.",
    details: [
        { 
            detail: "Description", 
            description: "Hoshin Kanri (management stratégique en japonais) est une démarche de déploiement de stratégie qui permet de fédérer l’ensemble du personnel de l'entreprise en partageant la vision stratégique et les moyens d'action. Hoshin Kanri organise l'action et les changements nécessaires pour matérialiser la vision de l'entreprise et atteindre les objectifs stratégiques."
        },
        { 
            detail: "Objectives", 
            description: (
                <p>
                    • Connaitre les principes et les étapes de la démarche Hoshin Kanri. <br />
                    • Connaire les principes et les les tâches spécifiques au Travail Standardisé
                    du Leader. <br />
                    • Assurer la pérennité du changement.
                </p>
            )
        },
        { 
            detail: "Durée", 
            description: "2 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Date de la session", 
            description: "26-27 / 02 / 2024"
        },
        { 
            detail: "Prix", 
            description: "900 DT(HT) /participant"
        }
    ]
    },
    {
      id: '4',
      title: 'Méthodes de Résolution de Problèmes (8D & A3)',
      description: "Les méthodes 8D (Ford) et A3 (Toyota) pour la résolution durable des problèmes.",
      details: [
          { 
              detail: "Description", 
              description: "Les méthodes de résolution de problèmes 8D (Ford) et A3 (Toyota) sont indispensables à l’amélioration continue. Elles permettent aux équipes de résoudre de façon durable la majorité de leurs problèmes quotidiens."
          },
          { 
              detail: "Objectives", 
              description: (
                  <p>
                      • Connaitre les étapes à suivre dans le cadre des méthodes de résolution de problèmes 8D et A3. <br />
                      • Maitriser les outils spécifiques aux méthodes de résolution de problèmes 8D et A3. <br />
                      • Mettre en œuvre les méthodes de résolution de problèmes dans le cadre de la démarche d’amélioration continue.
                  </p>
              )
          },
          { 
              detail: "Durée", 
              description: "3 jours, Horaire de la formation : de 08h30 à 16h00 (pauses incluses)"
          },
          { 
              detail: "Dates des sessions", 
              description: "Session 1: 21-23 / 02 / 2024, Session 2: 16-18 / 10 / 2024"
          },
          { 
              detail: "Prix", 
              description: "900 DT(HT) /participant"
          }
      ]
    },
    {
      id: '5',
    title: 'TPM (Total Productive Maintenance)',
    description: "Améliorer la performance globale des équipements de production.",
    details: [
        { 
            detail: "Description", 
            description: "Total Productive Maintenance (TPM) permet d’améliorer la performance globale des équipements de production. Atteindre le «zéro panne», «zéro défaut» font partie des cibles de la mise en œuvre d’une démarche TPM."
        },
        { 
            detail: "Objectives", 
            description: (
                <p>
                    • Connaître les enjeux d’une démarche TPM. <br />
                    • Connaitre les étapes de mise en œuvre de la démarche TPM. <br />
                    • Maitriser les outils spécifiques à la démarche TPM. <br />
                    • Piloter la mise en application d’une démarche TPM.
                </p>
            )
        },
        { 
            detail: "Durée", 
            description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Dates des sessions", 
            description: "Session 1: 14-16 / 02 / 2024, Session 2: 23-25 / 10 / 2024"
        },
        { 
            detail: "Prix", 
            description: "900 DT(HT) /participant"
        }
    ]
    },
    {
      id: '6',
      title: 'SMED (Single Minute Exchange of Dies)',
      description: "Réduction significative des temps de changement d’outillage.",
      details: [
          { 
              detail: "Description", 
              description: "La méthode SMED permet de réduire significativement et de façon systématique le temps de changement de l’outillage des équipements. La méthode SMED a été développée par Shigeo Shingo dans l'univers industriel de Toyota. Elle est indispensable à la « production au plus juste » (Just in Time)."
          },
          { 
              detail: "Objectives", 
              description: (
                  <p>
                      • Connaitre les principes et les avantages de la méthode SMED. <br />
                      • Connaitre les étapes et les techniques de la méthode SMED (Méthode Shingo). <br />
                      • Etre capable de piloter des chantiers SMED visant à réduire le temps de changement de l’outillage des équipements.
                  </p>
              )
          },
          { 
              detail: "Durée", 
              description: "2 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
          },
          { 
              detail: "Dates des sessions", 
              description: "Session 1: 07-08 / 03 / 2024, Session 2: 07-08 / 11 / 2024"
          },
          { 
              detail: "Prix", 
              description: "750 DT(HT) /participant"
          }
      ]
    },
    {
      id: '7',
    title: 'VSM (Value Stream Mapping)',
    description: "La cartographie VSM met en évidence les gaspillages et les pertes de productivité dans les processus de réalisation des produits et services permettant ainsi l’identification et la hiérarchisation rapide des axes d’amélioration des performances opérationnelles en fonction de leur impact sur le délai de réalisation et les coûts associés.",
    details: [
        { 
            detail: "Description", 
            description: "La cartographie VSM met en évidence les gaspillages et les pertes de productivité dans les processus de réalisation des produits et services permettant ainsi l’identification et la hiérarchisation rapide des axes d’amélioration des performances opérationnelles en fonction de leur impact sur le délai de réalisation et les coûts associés."
        },
        { 
            detail: "Objectives", 
            description: (
                <p>
                    • Maitriser les principes de la « production au plus juste » (Just in Time). <br />
                    • Être en mesure de réaliser vos propres VSM actuelles et cibles. <br />
                    • Utiliser la cartographie VSM pour définir et planifier les chantiers stratégiques à réaliser en vue d’améliorer les délais et la rentabilité de l’entreprise.
                </p>
            )
        },
        { 
            detail: "Durée", 
            description: "2 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Dates des sessions", 
            description: "Session 1 : 06-07 / 06 / 2024, Session 2 : 05-06 / 12 / 2024"
        },
        { 
            detail: "Prix", 
            description: "750 DT(HT) /participant"
        }
    ]
    },
    {
      id: '8',
    title: '5S Management Visuel & Kaizen',
    description: "Implémentation de stratégies d'amélioration continue.",
    details: [
        { 
            detail: "Description", 
            description: "Les principes et outils de base du Lean permettent l’optimisation de l’espace de travail, la réduction des gaspillages et des dysfonctionnements en vue de gagner en agilité et en productivité."
        },
        { 
            detail: "Objectives", 
            description: (
                <p>
                    • Maîtriser les principes fondamentaux du Lean.<br />
                    • Connaitre les 3 piliers du Lean.<br />
                    • Être capable de mettre en œuvre les outils Lean d’amélioration de la productivité 5S et Management visuel.<br />
                    • Maitriser la méthodologie de percée Kaizen.
                </p>
            )
        },
        { 
            detail: "Durée", 
            description: "2 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Dates des sessions", 
            description: "Session 1:  01-02 / 02 / 2024, Session 2: 12-13 / 12 / 2024"
        },
        { 
            detail: "Prix", 
            description: "750  DT(HT) /participant"
        }
    ]
    }
  ];
  
  export { trainings }; 
const LeanManufacturing = () => {
  const [selectedTraining, setSelectedTraining] = useState(null);

  // Function to handle detail view
  const handleDetailsView = (training) => {
    setSelectedTraining(training);
  };

  // Function to go back to the catalog
  const handleBackToCatalog = () => {
    setSelectedTraining(null);
  };

  // Render the catalog view
  const renderCatalog = () => (
    <div className="container mt-5">
      <div className="row">
      <h1 className="catalog-title">Formations - Lean Manifacturing</h1> {/* Add the title */}
        {trainings.map((training) => (
          <div className="col-md-3 mb-4" key={training.id}>
            <div className="training-item shadow text-center">
              <h2 className="card-title">{training.title}</h2>
              <div className="d-flex justify-content-center">
                <button
                  onClick={() => handleDetailsView(training)}
                  className="btn btn-primary"
                >
                  En savoir plus
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  // Render the details view
  const renderDetails = () => (
    <div className="container mt-5">
      <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
        Retour au Catalogue
      </button>
      
      <table className="details-table">
        <thead>
          <tr>
            <th colSpan="2" className="details-title">
              {selectedTraining.title}
            </th>
          </tr>
          <tr>
            <th>Details</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {selectedTraining.details.map((detail, index) => (
            <tr key={index}>
              <td>{detail.detail}</td>
              <td>{detail.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <Header />
      {selectedTraining ? renderDetails() : renderCatalog()}
      <Footer />
    </div>
  );
};

export default LeanManufacturing;