// src/pages/FormationPages/Environnement.js
import React, { useState } from 'react';
import './environnement.css'; // Import the CSS file
import Header from '../../components/Header'; // Import the Header component
import Footer from '../../components/Footer'; // Import the Footer component

// Sample data for the trainings
const trainings = [
    {
        id: 'sustainable-practices',
        title: 'Exigences de la Norme ISO 14001:2015',
        details: [
          { detail: "Description", description: "Découvrez comment développer un système de management environnemental efficace et efficient conforme à la norme ISO 14001:2015." },
          { 
            detail: "Objectives", 
            description: (
              <p>
                • Analyser et interpréter les exigences de la norme ISO 14001:2015 et savoir les appliquer à son entreprise <br />
                • Connaitre les outils et méthodes nécessaires pour la mise en place d’un SME conforme aux exigences de la norme ISO 14001:2015 <br />
                • Connaitre les principales exigences légales et règlementaires environnementales applicables en Tunisie
              </p>
            )
          },
          { detail: "Duration", description: "3 jours Horaire de la formation: de 08h30 à 16h00 (pauses incluses)" },
          { detail: "Dates des sessions :", description: (<p> • Session 1: 24-26 / 04 / 2024 <br /> 
          • Session 2: 25-27 / 09 / 2024 <br/> </p>)},
          { detail: "Price", description: "850 DT(HT) /participant" }
        ]
      },
      {
        id: 'environmental-regulations',
        title: 'Auditeur Interne ISO 14001:2015',
        details: [
            { 
                detail: "Description", 
                description: (
                    <p>
                        • La norme ISO 14001 positionne l’audit interne comme un outil de management incontournable pour la surveillance et la vérification de l’efficacité du système de management environnemental d’un organisme.
                    </p>
                )
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                        • Acquérir la qualification « auditeur interne ISO 14001:2015 »<br />
                        • Maitriser les techniques d’audit et de communication en situation d’audit et connaitre les principales exigences légales et règlementaires environnementales applicables en Tunisie<br />
                        • Etre capable de réaliser des audits internes à haute valeur ajoutée<br />
                        • Savoir rédiger un rapport d’audit basé sur l’amélioration
                    </p>
                )
            },
            { 
                detail: "Duration", 
                description: "3 jours Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: (
                    <p>
                        • Session 1: 19-21 / 06 / 2024<br />
                        • Session 2: 13-15 / 11 / 2024
                    </p>
                )
            },
            { 
                detail: "Price", 
                description: " 850 DT(HT) /participant"
            }
        ]
      }
    
];

const Environnement = () => {
    const [selectedTraining, setSelectedTraining] = useState(null);

    // Function to handle detail view
    const handleDetailsView = (training) => {
        setSelectedTraining(training);
    };

    // Function to go back to the catalog
    const handleBackToCatalog = () => {
        setSelectedTraining(null);
    };

    // Render the catalog view
    const renderCatalog = () => (
        <div className="training-catalog">
    <h1 className="catalog-title">Formations - Environnement</h1>
    <div className="training-list">
        {trainings.map((training) => (
            <div key={training.id} className="card-centered">
                <h3 className="card-title">{training.title}</h3>
                <p className="card-description">{training.description}</p>
                <button onClick={() => handleDetailsView(training)} className="btn">
                    En savoir plus
                </button>
            </div>
        ))}
    </div>
</div>
    );

    // Render the details view
    const renderDetails = () => (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
            Retour au Catalogue
            </button>
         
            <table className="details-table">
                <thead>
                    <tr>
                        <th colSpan="2" className="details-title">{selectedTraining.title} </th>
                    </tr>
                    <tr>
                        <th>Details</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedTraining.details.map((detail, index) => (
                        <tr key={index}>
                            <td>{detail.detail}</td>
                            <td>{detail.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="min-vh-100">
            <Header /> {/* Include the Header */}
            {selectedTraining ? renderDetails() : renderCatalog()}
            <Footer /> {/* Include the Footer */}
        </div>
    );
};

export default Environnement;
