import React from 'react';
import './LeadershipEtStrategieDetails.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// LeadershipEtStrategieDetails Component
const LeadershipEtStrategieDetails = () => {
  const sessions = [
    {
      id: 'strategic-management',
      title: 'Management Stratégique de l’Entreprise',
      details: [
        {
          detail: "Description",
          description: "Le management stratégique permet à l’entreprise de créer des avantages compétitifs durables. Il est primordial pour le développement et la pérennité de l’entreprise dans un environnement complexe et dynamique."
        },
        {
          detail: "Objectives",
          description: (
            <ul>
              <li>Connaitre les enjeux et maitriser les principes de la réflexion stratégique</li>
              <li>Comprendre la démarche et les composantes du management stratégique de l’entreprise</li>
              <li>Être en mesure de formuler et de mettre en œuvre des recommandations stratégiques à même de créer des avantages compétitifs durables.</li>
            </ul>
          )
        },
        {
          detail: "Durée",
          description: "Horaire de la formation: de 08h30 à 16h30 (pauses incluses)"
        },
        {
          detail: "Dates des sessions :",
          description: "Session 1: 05-09 / 02 / 2024 , Session 2: 09-13 / 09 / 2024"
        },
        {
          detail: "Prix",
          description: "1 500 DT (HT) /participant"
        }
      ]
    }
  ];

  return (
    <div className="details-container">
      <Header />
      <Table sessions={sessions} />
      <Footer />
    </div>
  );
};

// Table component for displaying session details
const Table = ({ sessions }) => (
  <table className="details-table">
    <thead>
  
      <tr>
        <th><i className="fa fa-book" aria-hidden="true"></i> Titre</th>
        <th><i className="fa fa-info-circle" aria-hidden="true"></i> Description</th>
        <th><i className="fa fa-bullseye" aria-hidden="true"></i> Objectives</th>
        <th><i className="fa fa-clock-o" aria-hidden="true"></i> Durée</th>
        <th><i className="fa fa-calendar" aria-hidden="true"></i> Dates des sessions</th>
        <th><i className="fa fa-dollar" aria-hidden="true"></i> Prix</th>
      </tr>
    </thead>
    <tbody>
      {sessions.map((session, index) => (
        <tr key={index}>
          <td>{session.title}</td>
          <td>
            {session.details.find(detail => detail.detail === "Description")?.description || "N/A"}
          </td>
          <td>
            {session.details.find(detail => detail.detail === "Objectives")?.description || "N/A"}
          </td>
          <td>
            {session.details.find(detail => detail.detail === "Durée")?.description || "N/A"}
          </td>
          <td>
            {session.details.find(detail => detail.detail === "Dates des sessions :")?.description || "N/A"}
          </td>
          <td>
            {session.details.find(detail => detail.detail === "Prix")?.description || "N/A"}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
export default LeadershipEtStrategieDetails;
