// src/pages/FormationPages/InnovationEtConception.js
import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './innovationetconception.css'; // Import the updated CSS

// Sample data for the trainings
const trainings = [
    {id: 'design-thinking',
        title: 'AMDEC Produit (Design FMEA)',
        details: [
            { 
                detail: "Description", 
                description: "La méthode AMDEC produit (Design FMEA) est une démarche préventive recommandée dans le cadre de la conception ou de la modification de la conception d'un produit, afin de lever tous les risques de défaillances ou d'insatisfaction client liés à l'utilisation et au fonctionnement du produit."
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                        • Mettre en œuvre la méthode AMDEC Produit (Design FMEA) conformément au manuel AMDEC AIAG-VDA (2019) <br />
                        • Connaitre les étapes de la méthode AMDEC Produit <br />
                        • Savoir identifier et évaluer les défaillances et déterminer la criticité des modes de défaillances <br />
                        • Définir un plan d’action et suivre son efficacité
                    </p>
                )
            },
            { 
                detail: "Durée", 
                description: "3 jours Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: "22-24 / 05 / 2024"
            },
            { 
                detail: "Prix", 
                description: "900 DT(HT) /participant"
            }
        ]
    },
    {id: 'product-development',
        title: 'Méthode TRIZ',
        details: [
            { 
                detail: "Description", 
                description: "TRIZ, ou « Théorie de la Résolution des Problèmes Inventifs » est une méthode de résolution des problèmes techniques de la conception permettant de développer de manière systématique des solutions innovantes en s'inspirant de l'expérience accumulée dans le domaine des inventions et de l'évolution des systèmes technologiques."
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                        • Connaitre et assimiler les concepts de base et les principes de résolution des problèmes de conception de la méthode TRIZ. <br />
                        • Être en mesure d’identifier et de résoudre les contradictions techniques inhérentes dans un système à travers la mise en oeuvre des quarante principes inventifs. <br />
                        • Connaitre et assimiler les huit modèles de l'évolution des systèmes technologiques. <br />
                        • Être en mesure de mettre en œuvre les outils de la méthode TRIZ.
                    </p>
                )
            },
            { 
                detail: "Durée", 
                description: "4 jours Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: "11-14 / 06 / 2024"
            },
            { 
                detail: "Prix", 
                description: "1 200 DT(HT) /participant"
            }
        ]
    },
    {
        id: 'innovation-strategies',
        title: 'Design Thinking',
        details: [
            { 
                detail: "Description", 
                description: "Le design thinking est une démarche d'innovation axée sur l’empathie, la créativité et l’expérimentation permettant de concevoir des solutions à haute valeur ajoutée axées sur l'utilisateur. Cette formation permet de découvrir et de pratiquer la démarche de design thinking en 5 étapes."
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                        • Assimiler les étapes clés de la démarche Design Thinking pour le développement de produits et services innovants <br />
                        • Connaitre et être en mesure de mettre en œuvre les différents outils du Design Thinking <br />
                        • Appréhender les bases du prototyping et du storytelling dans le cadre du Design Thinking <br />
                        • Coordonner le Design Thinking avec d'autres approches d'innovation
                    </p>
                )
            },
            { 
                detail: "Durée", 
                description: "3 jours Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: "08-10 / 05 / 2024"
            },
            { 
                detail: "Prix", 
                description: "900 DT(HT) /participant"
            }
        ]
    }
];

const InnovationEtConception = () => {
    const [selectedTraining, setSelectedTraining] = useState(null);

    // Function to handle detail view
    const handleDetailsView = (training) => {
        setSelectedTraining(training);
    };

    // Function to go back to the catalog
    const handleBackToCatalog = () => {
        setSelectedTraining(null);
    };

    // Render the catalog view
    // Render the catalog view
const renderCatalog = () => (
    <div className="training-catalog">
        <h1 className="catalog-title">Formations - Innovation et Conception</h1>
        <div className="training-list">
            {trainings.map((training) => (
                <div key={training.id} className="card-centered">
                    <h3 className="card-title">{training.title}</h3>
                    <p className="card-description">{training.description}</p>
                    <button onClick={() => handleDetailsView(training)} className="btn">
                        En savoir plus
                    </button>
                </div>
            ))}
        </div>
    </div>
);


    // Render the details view
    const renderDetails = () => (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
            Retour au Catalogue
            </button>
          
            <table className="details-table">
                <thead>
                    <tr>
                        <th colSpan="2" className="details-title">{selectedTraining.title} </th>
                    </tr>
                    <tr>
                        <th>Details</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedTraining.details.map((detail, index) => (
                        <tr key={index}>
                            <td>{detail.detail}</td>
                            <td>{detail.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="min-vh-100">
            <Header />
            {selectedTraining ? renderDetails() : renderCatalog()}
            <Footer />
        </div>
    );
};

export default InnovationEtConception;
