
// Galerie.js
import React from 'react';
import './galerie.css'; // Import your CSS for styling

// Correct import for Header and Footer
import Header from '../../components/Header'; // Adjust the path as necessary
import Footer from '../../components/Footer'; 

// Ensure slick-carousel CSS files are imported
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import the Slider component from react-slick
import Slider from 'react-slick';

// Import images directly
import about from '../../Images/1000697.JPG';
import carousel1 from '../../Images/1000702.JPG';
import carousel2 from '../../Images/1000737.JPG';
import cat1 from '../../Images/1000778.JPG';
import cat2 from '../../Images/1000800.JPG';
import cat3 from '../../Images/1000827.JPG';
import cat4 from '../../Images/1000968.JPG';
import course1 from '../../Images/1000970.JPG';
import course2 from '../../Images/1005797.JPG';
import course3 from '../../Images/1005800.JPG';
import course4 from '../../Images/1005814.JPG';
import course5 from '../../Images/1006110.JPG';
import course6 from '../../Images/1006113.JPG';
import course7 from '../../Images/1006116.JPG';
import course8 from '../../Images/1010451.JPG';
import course9 from '../../Images/1010453.JPG';
import course10 from '../../Images/20233005_181704.jpg';
import course11 from '../../Images/20240705_110022.jpg';
import course12 from '../../Images/20240712_131522.jpg';
import course13 from '../../Images/20241106_165606.jpg';
const Galerie = () => {
  // Define the images array with imported images
  const imagesArray = [
    { src: about, alt: 'about' },
    { src: carousel1, alt: 'carousel-1' },
    { src: carousel2, alt: 'carousel-2' },
    { src: cat1, alt: 'cat-1' },
    { src: cat2, alt: 'cat-2' },
    { src: cat3, alt: 'cat-3' },
    { src: cat4, alt: 'cat-4' },
    { src: course1, alt: 'course-1' },
    { src: course2, alt: 'course-2' },
    { src: course3, alt: 'course-2' },
    { src: course4, alt: 'course-2' },
    { src: course5, alt: 'course-2' },
    { src: course6, alt: 'course-2' },
    { src: course7, alt: 'course-2' },
    { src: course8, alt: 'course-2' },
    { src: course9, alt: 'course-2' },
    { src: course10, alt: 'course-2' },
    { src: course11, alt: 'course-2' },
    { src: course12, alt: 'course-2' },
    { src: course13, alt: 'course-2' },
  ];

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Automatically play the slider
    autoplaySpeed: 2000, // Change images every 3 seconds
  };

  return (
    <div>
      {/* Render Header and Footer, ensure they are valid components */}
      <Header />
      <div className="galerie-container">
        <div className="galerie-slider">
          <Slider {...settings}>
            {imagesArray.map((image, index) => (
              <div className="galerie-item" key={index}>
                <img src={image.src} alt={image.alt} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Galerie;