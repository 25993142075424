import React, { useState } from 'react';
import './certiBaseStyles.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



const SearchCertification = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [certType, setCertType] = useState(''); // État pour le type de certification
    const [certifications, setCertifications] = useState([]);
    const [searched, setSearched] = useState(false);

    
    const mockData =  [
        { firstName: "Walid", lastName: "Ben Kilani", certType: "Lean Six Sigma Black Belt", certNumber: "3300116" },
        {firstName: "Issam", lastName : "Saada", certType:" Lean Six Sigma Black Belt ", certNumber:"3300216"},
        {firstName: " Issam ", lastName: "Saada", certType:"Lean Six Sigma Master Black Belt", certNumber:"3400116"},
        { firstName: "Ines", lastName: "Chebaane", certType: "Lean Six Sigma Black Belt", certNumber: "3300117" },
        { firstName: "Anes", lastName: "Chetoui", certType: "Lean Six Sigma Green Belt", certNumber: "3200118" },
        { firstName: "Anes", lastName: "Chetoui", certType: "Lean Six Sigma Black Belt", certNumber: "3300118" },
        { firstName: "Adnen", lastName: "Ben Sedrine", certType: "Lean Six Sigma Black Belt", certNumber: "3300119" },
        { firstName: "Yaacoub", lastName: "Raddadi", certType: "Six Sigma Green Belt", certNumber: "2200119" },
        { firstName: "Sofiene", lastName: "Belkhiria", certType: "Six Sigma Green Belt", certNumber: "2200219" },
        { firstName: "Mondher", lastName: "Jendoubi", certType: "Lean Six Sigma Green Belt", certNumber: "3200119" },
        { firstName: "Sameh", lastName: "Ayari", certType: "Lean Six Sigma Green Belt", certNumber: "3200219" },
        { firstName: "Abdel Monem", lastName: "Ben Naya", certType: "Lean Six Sigma Green Belt", certNumber: "3200319" },
        { firstName: "Rafik", lastName: "Somri", certType: "Lean Six Sigma Green Belt", certNumber: "3200419" },
        { firstName: "Mohamed", lastName: "Derguech", certType: "Lean Six Sigma Green Belt", certNumber: "3200519" },
        { firstName: "Imene", lastName: "Turki", certType: "Lean Six Sigma Green Belt", certNumber: "3200619" },
        { firstName: "Mohamed Ali", lastName: "Yakoubi", certType: "Lean Six Sigma Green Belt", certNumber: "3200719" },
        { firstName: "Chaima", lastName: "Sayari", certType: "Lean Six Sigma Green Belt", certNumber: "3200819" },
        { firstName: "Skander", lastName: "Mammou", certType: "Lean Six Sigma Green Belt", certNumber: "3200919" },
        { firstName: "Mohamed Amine", lastName: "Saidi", certType: "Lean Six Sigma Green Belt", certNumber: "3201019" },
        { firstName: "Mohamed Ali", lastName: "Khalfaoui", certType: "Lean Six Sigma Green Belt", certNumber: "3201119" },
        { firstName: "Kamel", lastName: "Ben Ghzaiel", certType: "Lean Six Sigma Green Belt", certNumber: "3201219" },
        { firstName: "Ibrahim", lastName: "Sadfi", certType: "Lean Six Sigma Green Belt", certNumber: "3200120" },
        { firstName: "Asma", lastName: "Abidi", certType: "Lean Six Sigma Green Belt", certNumber: "3200220" },
        { firstName: "Hamza", lastName: "Hamdi", certType: "Lean Six Sigma Green Belt", certNumber: "3200320" },
        { firstName: "Aymen", lastName: "Lahmar", certType: "Lean Six Sigma Green Belt", certNumber: "3200420" },
        { firstName: "Samar", lastName: "Boughdiri", certType: "Lean Six Sigma Green Belt", certNumber: "3200520" },
        { firstName: "Fatma", lastName: "Khadhraoui", certType: "Lean Six Sigma Green Belt", certNumber: "3200620" },
        { firstName: "Lamjed", lastName: "Whibi", certType: "Lean Six Sigma Green Belt", certNumber: "3200720" },
        { firstName: "Amine", lastName: "Zouabi", certType: "Lean Six Sigma Green Belt", certNumber: "3200820" },
        { firstName: "Mourad", lastName: "Kettani", certType: "Lean Six Sigma Green Belt", certNumber: "3200920" },
        { firstName: "Ilyes", lastName: "Razgui", certType: "Lean Six Sigma Green Belt", certNumber: "3201020" },
        { firstName: "Safouane", lastName: "Saidane", certType: "Lean Six Sigma Green Belt", certNumber: "3201120" },
        { firstName: "Ilyes", lastName: "Hammami", certType: "Six Sigma Green Belt", certNumber: "2200120" },
        { firstName: "Anis", lastName: "Ben Yahia", certType: "Six Sigma Green Belt", certNumber: "2200320" },
        { firstName: "Hassen", lastName: "Affes", certType: "Six Sigma Green Belt", certNumber: "2200420" },
        { firstName: "Zahra", lastName: "Labidi", certType: "Six Sigma Green Belt", certNumber: "2200520" },
        { firstName: "Zeineb", lastName: "Cherni", certType: "Lean Six Sigma Black Belt", certNumber: "3300120" },
        { firstName: "Chayma", lastName: "Hawala", certType: "Lean Six Sigma Green Belt", certNumber: "3200121" },
        { firstName: "Omar", lastName: "Khaled", certType: "Lean Six Sigma Green Belt", certNumber: "3200221" },
        { firstName: "Mohamed", lastName: "Hammami", certType: "Lean Six Sigma Green Belt", certNumber: "3200321" },
        { firstName: "Jamel", lastName: "Kouki", certType: "Lean Six Sigma Green Belt", certNumber: "3200421" },
        { firstName: "Fethi", lastName: "Chen", certType: "Lean Six Sigma Green Belt", certNumber: "3200721" },
        { firstName: "Chamseddine", lastName: "Kmiti", certType: "Lean Six Sigma Green Belt", certNumber: "3200821" },
        { firstName: "Houssine", lastName: "Jlassi", certType: "Lean Six Sigma Green Belt", certNumber: "3200921" },
        { firstName: "Hamza", lastName: "Hammami", certType: "Lean Six Sigma Green Belt", certNumber: "3201021" },
        { firstName: "Nizar", lastName: "Bouzaien", certType: "Lean Six Sigma Green Belt", certNumber: "3201121" },
        { firstName: "Ali", lastName: "Khaled", certType: "Lean Six Sigma Green Belt", certNumber: "3201221" },
        { firstName: "Sameh", lastName: "Ayari", certType: "Lean Six Sigma Black Belt", certNumber: "3300121" },
        { firstName: "Abdel Monem", lastName: "Ben Naya", certType: "Lean Six Sigma Black Belt", certNumber: "3300221" },
        { firstName: "Mondher", lastName: "Jendoubi", certType: "Lean Six Sigma Black Belt", certNumber: "3300321" },
        { firstName: "Fatma", lastName: "Khadhraoui", certType: "Lean Six Sigma Black Belt", certNumber: "3300421" },
        { firstName: "Aymen", lastName: "Lahmar", certType: "Lean Six Sigma Black Belt", certNumber: "3300521" },
        { firstName: "Mohamed Amine", lastName: "Saidi", certType: "Lean Six Sigma Black Belt", certNumber: "3300621" },
        { firstName: "Hamdi", lastName: "Belgacem", certType: "Lean Six Sigma Green Belt", certNumber: "3200122" },
        { firstName: "Abdelmonaam", lastName: "Hedyaoui", certType: "Lean Six Sigma Green Belt", certNumber: "3200222" },
        { firstName: "Habib", lastName: "Ben Tahar", certType: "Lean Six Sigma Green Belt", certNumber: "3200322" },
        { firstName: "Abdallah", lastName: "Hammami", certType: "Lean Six Sigma Green Belt", certNumber: "3200422" },
        { firstName: "Marwa", lastName: "Mansouri", certType: "Lean Six Sigma Green Belt", certNumber: "3200522" },
        {firstName:"Slim" , lastName:"Hsan", certType:"Lean Six Sigma Green Belt", certNumber:"3200622"},
        {firstName: "Haifa", lastName: "Rezgui", certType: "Lean Six Sigma Green Belt", certNumber: "3200722"},
        {firstName: "Ahmed", lastName: "Guizani", certType: "Lean Six Sigma Green Belt", certNumber: "3200822"},
        {firstName: "Heni", lastName: "Bahaeddine", certType: "Lean Six Sigma Green Belt", certNumber: "3200922"},
        {firstName: "Aymen", lastName: "Hdhily", certType: "Lean Six Sigma Green Belt", certNumber: "3201022"},
        {firstName: "Akrem", lastName: "Daoudi", certType: "Lean Six Sigma Green Belt", certNumber: "3201122"},
        {firstName: "Ali", lastName: "Gharsa", certType: "Lean Six Sigma Green Belt", certNumber: "3201222"},
        {firstName: "Ahlem", lastName: "Rabhi", certType: "Lean Six Sigma Green Belt", certNumber: "3201322"},
        {firstName: "Hamdi", lastName: "Zouaghi", certType: "Lean Six Sigma Green Belt", certNumber: "3201422"},
        {firstName: "Amel", lastName: "Belgacem", certType: "Lean Six Sigma Green Belt", certNumber: "3201522"},
        {firstName: "Karima", lastName: "Yakoubi", certType: "Lean Six Sigma Green Belt", certNumber: "3200123"},
        {firstName: "Emna", lastName: "Amiri", certType: "Lean Six Sigma Green Belt", certNumber: "3200223"},
        {firstName: "Kawthar", lastName: "Sellami", certType: "Lean Six Sigma Green Belt", certNumber: "3200323"},
        {firstName: "Majdi", lastName: "Neily", certType: "Lean Six Sigma Green Belt", certNumber: "3200423"},
        {firstName: "Mortadha", lastName: "Barbouche", certType: "Lean Six Sigma Green Belt", certNumber: "3200523"},
        {firstName: "Imen", lastName: "Mouelhi", certType: "Lean Six Sigma Green Belt", certNumber: "3200623"},
        {firstName: "Allala", lastName: "Khalfa", certType: "Lean Six Sigma Green Belt", certNumber: "3200723"},
        {firstName: "Sahar", lastName: "Hammami", certType: "Lean Six Sigma Green Belt", certNumber: "3200823"},
        {firstName: "Houssem Eddine", lastName: "Bouain", certType: "Lean Six Sigma Green Belt", certNumber: "3200923"},
        {firstName: "Achraf", lastName: "Ouerghemmi", certType: "Lean Six Sigma Green Belt", certNumber: "3201023"},
        {firstName: "Hachem", lastName: "Nalouti", certType: "Lean Six Sigma Green Belt", certNumber: "3201123"},
        {firstName: "Claudius", lastName: "Gleichmar", certType: "Lean Six Sigma Green Belt", certNumber: "3200124"},
        {firstName: "Mario", lastName: "Becker", certType: "Lean Six Sigma Green Belt", certNumber: "3200224"},
        {firstName: "Marc", lastName: "Richter", certType: "Lean Six Sigma Green Belt", certNumber: "3200324"},
        {firstName: "Puja", lastName: "Shinde", certType: "Lean Six Sigma Green Belt", certNumber: "3200424"},
        {firstName: "Mariem", lastName: "Belhaj Mabrouk", certType: "Lean Six Sigma Green Belt", certNumber: "3200524"},
        {firstName: "Saber", lastName: "Sliti", certType: "Lean Six Sigma Green Belt", certNumber: "3200624"},
        {firstName: "Emna", lastName: "Amiri", certType: "Lean Six Sigma Black Belt", certNumber: "3300124"},
        {firstName: "Samar", lastName: "Boughdiri", certType: "Lean Six Sigma Black Belt", certNumber: "3300224"},
        {firstName: "Hamza", lastName: "Hamdi", certType: "Lean Six Sigma Black Belt", certNumber: "3300324"},
        {firstName: "Hachem", lastName: "Nalouti", certType: "Lean Six Sigma Black Belt", certNumber: "3300424"},
        {firstName: "Kawther", lastName: "Sellami", certType: "Lean Six Sigma Black Belt", certNumber: "3300524"},
        {firstName: "Rafik", lastName: "Somri", certType: "Lean Six Sigma Black Belt", certNumber: "3300624"},
        {firstName: "Lamjed", lastName: "Whibi", certType: "Lean Six Sigma Black Belt", certNumber: "3300724"},
        {firstName: "Karima", lastName: "Yakoubi", certType: "Lean Six Sigma Black Belt", certNumber: "3300824"},
        {firstName: "Mohamed Ali", lastName: "Yakoubi", certType: "Lean Six Sigma Black Belt", certNumber: "3300924"},
        {firstName: "Majdi", lastName: "Neily", certType: "Lean Six Sigma Black Belt", certNumber: "3301024"},
        {firstName: "Mondher", lastName: "Jendoubi", certType: "Lean Six Sigma Master Black Belt", certNumber: "3400124"},
        { firstName: "Hedia", lastName: "Toukabri", certType: "Lean Six Sigma Green Belt", certNumber: "3200622" },
        { firstName: "Ezzeddine", lastName: "Fradi", certType: "Lean Six Sigma Green Belt", certNumber: "3200722" },
        { firstName: "Sofiene", lastName: "Ben Ayed", certType: "Lean Six Sigma Green Belt", certNumber: "3200822" },
        { firstName: "Khaled", lastName: "Chadli", certType: "Lean Six Sigma Green Belt", certNumber: "3200922" },
    ];
    
   /// Function to handle the search
   const handleSearch = () => {
    const filteredCertifications = mockData.filter(cert =>
        cert.firstName.toLowerCase().includes(firstName.toLowerCase()) &&
        cert.lastName.toLowerCase().includes(lastName.toLowerCase()) &&
        cert.certNumber.includes(certType)
    );
    setCertifications(filteredCertifications);
    setSearched(true);
};

return (
    <div className="search-container">
        <h2 className="text-center">CERTIBASE</h2>
        <div className="d-flex justify-content-center mb-3">
            <TextField
                label="Prénom"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="search-input"
            />
            <TextField
                label="Nom"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="search-input"
            />
            <TextField
                label="Numéro du certificat"
                variant="outlined"
                value={certType}
                onChange={(e) => setCertType(e.target.value)}
                type="text"
                className="search-input"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                className="search-button"
            >
                Chercher
            </Button>
        </div>

        {searched && (
            <>
                {certifications.length > 0 ? (
                    <div className="table-responsive">
                        <table className="table table-striped certification-table">
                            <thead>
                                <tr>
                                    <th>Prénom</th>
                                    <th>Nom</th>
                                    <th>Type certification</th>
                                    <th>Numéro certification</th>
                                </tr>
                            </thead>
                            <tbody>
                                {certifications.map((cert, index) => (
                                    <tr key={index}>
                                        <td>{cert.firstName}</td>
                                        <td>{cert.lastName}</td>
                                        <td>{cert.certType}</td>
                                        <td>{cert.certNumber}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="text-center">Aucune certification trouvée.</p>
                )}
            </>
        )}
    </div>
);
};

export default SearchCertification;