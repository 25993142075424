import React from 'react';
import Header from '../components/Header'; // Import the Header component
import Footer from '../components/Footer'; // Import the Footer component

const DesignThinkingDetails = () => {
  const courseDetails = {
    id: 'innovation-strategies',
    title: 'Design Thinking',
    details: [
        { 
            detail: "Description", 
            description: "Le design thinking est une démarche d'innovation axée sur l’empathie, la créativité et l’expérimentation permettant de concevoir des solutions à haute valeur ajoutée axées sur l'utilisateur. Cette formation permet de découvrir et de pratiquer la démarche de design thinking en 5 étapes."
        },
        { 
            detail: "Objectives", 
            description: (
                <p>
                    • Assimiler les étapes clés de la démarche Design Thinking pour le développement de produits et services innovants<br />
                    • Connaitre et être en mesure de mettre en œuvre les différents outils du Design Thinking<br />
                    • Appréhender les bases du prototyping et du storytelling dans le cadre du Design Thinking<br />
                    • Coordonner le Design Thinking avec d'autres approches d'innovation
                </p>
            )
        },
        { 
            detail: "Duration", 
            description: "3 jours Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
        },
        { 
            detail: "Dates des sessions", 
            description: (
                <p>
                    • Session 1: 08-10 / 05 / 2024<br />
                </p>
            )
        },
        { 
            detail: "Price", 
            description: "900 DT(HT) /participant"
        }
    ]
};


  return (
    <>
      <Header />
      <div className="container py-5">
        <h2>{courseDetails.title}</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Detail</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {courseDetails.details.map((item, index) => (
              <tr key={index}>
                <td><strong>{item.detail}</strong></td>
                <td>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default DesignThinkingDetails;
