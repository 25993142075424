import React from 'react';
import './processusdecertification.css'; // Import your CSS for styling
import Header from '../../components/Header'; // Adjust the path as necessary
import Footer from '../../components/Footer'; 

// Import the image
import certificationImage from '../../Images/Cer.jpg'; // Adjust the path to your image file

const ProcessusCertification = () => {
  return (
    <div>
      <Header />
      <div className="processus-certification-container">
        
        {/* Image added before the text */}
        <div className="certification-image-container">
          <img src={certificationImage} alt="Certification Banner" className="certification-image" />
        </div>
 {/* Page Title */}
 <h1 className="page-title">Processus de certification</h1>
        <p>
          Lean Management Institute propose les formations certifiantes suivantes:
        </p>
        <ul>
          <li>Lean Six Sigma Green Belt</li>
          <li>Lean Six Sigma Black Belt</li>
        </ul>
        <p>
          Au terme de ces formations, les connaissances et compétences développées par les candidats sont validées par une certification du Lean Management Institute.
        </p>
        <p>
          L’obtention de la certification est soumise aux critères suivants:
        </p>
        
        <div className="process-steps">
          <div className="step">
            <div className="circle">1</div>
            <div className="step-description">
              <h3>Assiduité et participation active</h3>
            </div>
          </div>
    
          
          <div className="step">
            <div className="circle">2</div>
            <div className="step-description">
              <h3>Réussite de l'examen final</h3>
            </div>
          </div>
          
          

          <div className="step">
            <div className="circle">3</div>
            <div className="step-description">
              <h3>Rédaction du rapport du projet</h3>
            </div>
          </div>

     

          <div className="step">
            <div className="circle">4</div>
            <div className="step-description">
              <h3>Certification</h3>
            </div>
          </div>
        </div>

        
      </div>
      <Footer />
    </div>
  );
};

export default ProcessusCertification;
